body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

body::-webkit-scrollbar {
  width: 8%;
  background: transparent;
}

body::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  padding: 3px;
}

body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  cursor: pointer;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.rsc-float-button{
  background-color: rgb(90, 177, 253) !important;
}

.rsc-ts-bubble{
  max-width: 75% !important;
}
.rsc-container{
  font-family: Mulish, sans-serif !important;
  letter-spacing: 0.5px !important;
}
.rsc-os-option-element{
  background: #2898ec4f !important;
  color: rgb(88, 90, 94) !important;
}
.rsc-os{
  margin-left: 46px !important;
}

.rsc-content::-webkit-scrollbar {
  width: 8% !important;
  background: transparent !important;
}
.rsc-header-title{
  font-size: 18px !important;
  font-weight: bold !important;
}
