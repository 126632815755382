@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comme:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comme:wght@400;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;1,300;1,500&family=Ysabeau+Infant:wght@300&family=Ysabeau:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comme:wght@200&family=Poppins:ital,wght@0,700;1,300;1,500&family=Ysabeau+Infant:wght@300&family=Ysabeau:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Cairo");

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  white-space: wrap;
}
.year10 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: italic;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 2;
}

.year {
  /* background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif); */
  background-size: cover;
  color: #f3d05f;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  /* text-transform: uppercase; */
  font-size: 17px;
  margin: 8px 0;
  font-weight: 700;
  margin-left: -15px;
}

.container3 {
  height: 100%;
  width: 100%;
}
.anni-img {
  width: 100px;
  margin-left: 5px;
}

.p {
  position: absolute;
  font-size: 2rem;
  font-family: sans-serif;
  font-weight: bold;
  top: 65px;
  left: 65px;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.serv-btn {
  width: fit-content;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 5px;
  font-size: 12px;
}
.p1 {
  color: #fff;
}

.p2 {
  color: #00b5dc;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  animation: text-animation 3s ease-in-out forwards alternate infinite;
}

.p3 {
  height: 20%;
  color: transparent;
}

@keyframes text-animation {
  from {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0, 0% 1%, 0% 100%, 0% 100%);
  }
}

.cursor {
  position: absolute;
  top: 50%;
  height: 100%;
  width: 100%;
  transform: translate(0, -50%);
  border-right: 2px solid red;
  animation: cursor-animation 3s ease-in-out forwards alternate infinite;
}

@keyframes cursor-animation {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.main {
  height: 100vh;
}
.main-section {
  scroll-snap-align: start;
}
video {
  height: 100vh !important;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.8);
  background-image: url("../assets/img/PosterImage.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 0px;
}
.main-cont {
  width: 100%;
  height: 100vh;
  margin: 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 80%;
  top: 10%;
}

.nav-cont {
  position: fixed;
  height: 90%;
  width: 90%;
  background: #00000037 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 50px 0px #0000003c;
  box-shadow: 0px 16px 50px 0px #0000003c;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 20px;
  border: 3px solid #80808000;
  opacity: 0.5;
  z-index: 19;
}

.box1 {
  align-self: flex-end;
}
.navbar-cont {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 85%;
  position: fixed;
  top: 11%;
  z-index: 20;
  /* animation-name: examplenav;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards; */
}

@keyframes examplenav {
  from {
    top: 0px;
  }
  to {
    top: 11%;
  }
}
.content {
  z-index: 20;
  position: fixed;
  left: 7%;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 86%;
  top: 43%;
}
.main-text-cont {
  z-index: 19;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 77%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading {
  font-size: clamp(30px, 5vw, 60px);
  font-family: "Comme", "ExtraBold";
  text-align: center;
  font-weight: bolder;
  color: white;
}
.heading2 {
  font-size: clamp(30px, 5vw, 45px);
  font-family: "Comme", "ExtraBold";
  text-align: center;
  font-weight: bolder;
  color: white;
}
.para {
  font-size: clamp(10px, 4vw, 20px);
  font-family: "Comme", "Regular";
  text-align: center;
  color: white;
}

.explore-btn {
  align-self: center;
  color: white;
  background-color: transparent;
  border: 0px solid white;
  border-radius: 8px;
  font-size: 15px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal medium 16px/25px Poppins;
  margin-top: 10px;
  padding: 2px;
}
.page-tab {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.page {
  width: 18px;
  height: 10px;
  background-color: white;
  margin-bottom: 18px;
  border-radius: 30px;
  transition: 0.2s;
  cursor: pointer;
}
.Active-box {
  transition: 0.2s;
  background-color: rgb(212, 209, 209);
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #4c49cf;
  border-radius: 5px;
  width: 70%;
  padding-top: 5px;
  font-size: 16px;
  padding-bottom: 0px;
}
.cont {
  height: 100vh;
}
.scrollRotate {
  transition: 3s;
  transform: rotate(450deg);
}
#ButtonCont {
  cursor: pointer;
}
.menu-logo {
  width: 40px;
  cursor: pointer;
}
.cursorpointer {
  cursor: pointer;
}
.btn_arrow {
  margin-bottom: 5px;
  font-size: 20px;
  transition: all 0.3s;
  animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.active {
  width: 35px;
  transition: 0.2s;
}

@keyframes example {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(5px, 0px, 0);
  }
}
.sub-section {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.scrollbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 135px;
}

/*-------section-------*/

/*------ carousel------*/
.about-carousel {
  padding-right: 13px;
  background-color: white;
  border-radius: 15px;
}
.card-carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0px;
  margin-left: 0px;
}
.carousel-item {
  width: 100% !important;
  left: 0% !important;
}
.card-item {
  border-radius: 15px;
  height: 40vh;
  width: 100%;
  background-color: white;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.card-para {
  font: normal normal 600 14px/19px Poppins;
  letter-spacing: 0px;
  color: #0b0d39;
  opacity: 0.6;
}
.img-set {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #eef9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 3%;
  padding-bottom: 3%; */
}
.carousel-img {
  width: 50%;
}
.dots {
  font-size: 30px;
  color: #306e5b;
}
.car-arrow {
  font-size: 25px;
  color: #306e5b;
}

.right {
  opacity: 0.8;
}
.client-testimonial {
  width: 90%;
}
.client-testimonial1 {
  width: 75%;
}
.testimonial-item {
  background-color: white;
  width: 90%;
  height: 214px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
}

.card-head {
  color: #59595c;
  font-size: clamp(0.9rem, 3vw, 1.25rem);
  font-family: "Poppins";
  margin-bottom: 20px;
}
.client {
  background-color: white;
  width: 90%;
  height: 40vh;
  /* height: 360px; */
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px 0px;
}
.client1 {
  background-color: white;
  width: 95%;
  height: 92px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.client-head {
  font: normal normal bold 20px/35px Poppins;
  letter-spacing: 0px;
  color: #0b0d39;
}
.slick-slider {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.slickArrow {
  color: white;
  font-size: 35px;
  width: 200px;
}
.section4_arrows {
  color: white;
  font-size: 35px;
  width: 500px;
  z-index: 22;
  cursor: pointer;
}
.slick-left {
  margin-right: 20px;
}
.slick-right {
  margin-left: 20px;
}
.company-logo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  padding: 3px;
}
.company-name {
  font-size: clamp(15px, 3vw, 25px);
  color: #0b0d39;
  font-family: "Poppins";
}
.gallary {
  color: #007db8;
}
.gallary-img {
  width: 458px;
  height: 325px;
  margin: 10px;
  object-fit: content;
  object-position: center;
}
.gallarypics {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/*------ carousel-------*/
.wrapper1 {
  position: absolute;
  /* z-index: 70; */
  height: 167%;
  width: 106%;
  top: -36px;
  left: -17px;
}

/*------subSections--------*/
.bg-container {
  background: #353396;
}
.bg-container7 {
  background: #06245C;
}
.bg-container2 {
  background: #306e5b 0% 0% no-repeat padding-box;
}
.bg-container3 {
  background: #306e5b 0% 0% no-repeat padding-box;
}
.bg-container4 {
  background: #6323b8;
}
.bg-container5 {
  background: #b66e0f 0% 0% no-repeat padding-box;
}
.bg-container6 {
  background: #4a50a7 0% 0% no-repeat padding-box;
}
.container6 {
  background: #c23b23;
}
.container1 {
  background: #306e5b 0% 0% no-repeat padding-box;
}
.container3 {
  background: #4a50a7 0% 0% no-repeat padding-box;
}
.container4 {
  background: #6323b8;
}
.container2 {
  background: #b66e0f 0% 0% no-repeat padding-box;
}
.container5 {
  background: #4c49cf 0% 0% no-repeat padding-box;
}
.sec-one-img {
  mix-blend-mode: screen;
  opacity: 0.7;
}
.content-card {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 19;
}



/*------subSections--------*/
.stackimgs {
  width: 50%;
}
/*--------section2---------*/
.about-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
}
.active-cilents {
  background-color: white;
  height: fit-content;
  border-radius: 8px;
  color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 2px;
}
.btn_sec_1 {
  align-self: center;
  border: 1px solid white;
}
.btn_sec_2 {
  align-self: self-start;
  background-color: #7d78e6;
}
.btn_sec_3 {
  background-color: #5ba28d;
  align-self: flex-start;
  margin-bottom: 3%;
}
.btn_sec_4 {
  background-color: #976ad2;
  align-self: center;
}
.btn_sec_5 {
  background-color: #6e73bf;
  align-self: flex-start;
}
.item-client {
  line-height: 0.7;
}
.sec-num {
  font-size: clamp(12px, 2.5vw, 36px);
  font-weight: bold;
  color: #4c49cf;
}
.sec-text {
  font-size: clamp(10px, 2vw, 18px);
  font-family: "Poppins";
  color: #5b58dcab;
  margin-bottom: 3px;
}

.kode {
  color: #c4c1ff;
  font-weight: bold;
}
.kode-2 {
  color: #7cc7b0;
}
.kode-3 {
  color: #b78cef;
}
.kode-4 {
  color: #f5bc71;
}
.kode-5 {
  color: #a3a8f5;
}
.about-head {
  font-size: clamp(20px, 3vw, 40px);
  font-family: "Poppins";
  font-weight: 700;
}
.about-para {
  font-size: clamp(12px, 2vw, 20px);
  align-self: center;
  text-align: center;
  font-family: "Poppins";
  text-align: left;
}
/*--------section2---------*/
/*--------section3---------*/
.deliever-content {
  margin-right: 12px;
}


/*--------section3---------*/

/*---------section4-------*/
.service-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  line-height: 1.5;
}
.service-head {
  text-align: center;
}
.service-para {
  width: 80%;
  text-align: center;
}
/*---------section4-------*/
/*--------section6-----*/
.service-img {
  width: 80%;
}


/*--------section6-----*/

/*-----footer----*/
.footer-main-section {
  z-index: 1000;
  position: "relative";
  top: "0px";
  bottom: "0px";
  background-color: #c23b23;
}
a {
  text-decoration: none;
}
.footer-text {
  text-align: left;
  font: normal normal 600 24px/35px Poppins;
  font-size: clamp(16px, 2.5vw, 24px);
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
a {
  font: normal normal 16px / Poppins;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 1;
  line-height: 1;
}
.footer-content {
  display: flex;
  justify-content: space-around;
}
.icons {
  display: flex;
  justify-content: center;
  align-self: center;
}
.footer-icon {
  font-size: 30px;
  margin: 3px;
}
.bg-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: center;
}
.contact-info {
  /* display: flex; */
  justify-content: space-around;
  text-align: left;
}
.footer-sub-img-bg {
  /* display: flex; */
  flex-direction: row;
  font-size: clamp(12px, 2.5vw, 20px);
  text-align: left;
  margin-top: 15px;
}
.US-img {
  width: 100px;
  object-fit: contain;
  opacity: 1;
}
.contact-bg-heading {
  font: normal normal medium 20px/30px Poppins;
}
.contact-bg-paragraph {
  font: normal normal normal 15px/28px Poppins;
  /* font-size: clamp(12px, 2.5vw, 16px); */
  opacity: 0.9;
}
.footer-socialmedia-text {
  font-size: clamp(16px, 2.5px, 20px);
  margin-right: 5px;
  margin-top: 4px;
}
.setFooter {
  width: 100%;
  position: absolute;
  left: 0%;
  z-index: 21;
}
.foot {
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
}
.changeText {
  color: #007db8;
}
.listText {
  font: normal normal normal 15px/42px Poppins;
  letter-spacing: 0px;
  color: #757575;
}
.list {
  color: white;
}
.changeHead,
.changePara {
  color: #757575;
}
.footer-social-icons {
  color: #757575;
}

/*-------footer-------*/

/*-------about--------*/
.aboutVideo {
  height: 65vh !important;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.7);
  background-image: url("../assets/img/lazyabout.png") !important;
}
.welcome {
  width: 100%;
  height: 65vh;
  position: absolute;
  top: 0vh;
  background: transparent linear-gradient(180deg, #000000 0%, #1889bef5 100%) 0% 0% no-repeat padding-box;
  opacity: 0.74;
}
.horizon {
  width: 20%;
    background-color: #007DB8;
    height: 3px;
    border: 0;
    opacity: 1;
    margin: 0 0 15px;
}
.aboutHead {
  font: normal normal 500 30px/36px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.aboutHead1 {
  font: normal normal 500 25px/36px Poppins;
  letter-spacing: 0px;
  opacity: 1;
}
.aboutKode {
  /* color: #007db8; */
  font-size: 38px;
  font-weight: 600;
}
.aboutKode1 {
  color: #3167BA;
  font-size: 30px;
  font-weight: 600;
}
.aboutPara {
  font: normal normal normal 18px/30px Poppins;
  letter-spacing: 0.72px;
  color: #ffffff;
  opacity: 1;
}
.welContent {
  top: 25vh;
  z-index: 8;
  padding: 0 30px;
}
.aboutDriven {
  /* background: #f8f8f8 0% 0% no-repeat padding-box;
  width: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center; */
  background: #f8f8f8 0% 0% no-repeat padding-box;
  width: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2%;
}
.achieveImg {
  width: 35%;
  padding-top: 30px;
  height: 40vh;
  margin-bottom: 2%;
}

.drivenHead {
  /* font: normal normal medium 20px/66px Poppins; */
  font-size: 28px;
  letter-spacing: 0px;
  color: #575f63;
}
.drivenSpan {
  font: normal normal 800 34px/66px Poppins;
  letter-spacing: 0px;
  color: #14658b;
}
.codeImg {
  width: 80%;
  height: 40vh;
  margin-top: 10px;
}
.decade {
  width: 75%;
  padding: 40px 0px;
}
.templateHead {
  font: normal normal 600 36px/66px Poppins;
  letter-spacing: 0px;
  color: #575f63;
}
.templatePara {
  font: normal normal 500 20px/30px Poppins;
  letter-spacing: 0.8px;
  color: #5b5b5b;
}
.goalImg {
  width: 23%;
  filter: grayscale(100%);
}
.goalPara {
  font: normal normal 400 26px/36px Poppins;
  letter-spacing: 0px;
  color: #808080;
}
.goalPara1 {
  font: normal normal normal 18px/32px Poppins;
  letter-spacing: 0px;
  color: #909090;
  width: 80%;
}
.goalContent {
  width: 62%;
}
.seperate {
  width: 60%;
}
.subtile {
  font: normal normal 600 34px/36px Poppins;
}

/*-------about--------*/

/*-------event---------*/
.event-btn {
  background: #007db8 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: 0px solid white;
  font: normal normal 500 20px/30px Poppins;
  letter-spacing: 0.8px;
  color: #ffffff;
  opacity: 1;
  width: 442px;
  height: 52px;
}
.btn-event {
  background-color: white;
  border: 2px solid #007db8;
  color: #007db8;
}


/*-------event---------*/

/* Header-start */
.main-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 9vh;
  box-shadow: 0px 3px 6px #00000029;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 78%;
}
.menu-items {
  display: flex;
  justify-content: end;
  align-items: center;
  align-content: center;
  width: 55%;
  gap: 30px;
}
.image {
  width: 160px;
  cursor: pointer;
}
.button-item, #dropdown-basic {
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #1b2336;
  letter-spacing: 0.4px;
  opacity: 1;
  z-index: 77;
  cursor: pointer;
  padding: 0;
}
.btn.show, .btn:first-child:active {
  border-color: transparent;
}

.menu-items .dropdown-item {
  padding: 10px 15px;
}
.selectItem {
  color: #017db8;
}
/* Services */
.main-card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.card-container1 {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}
.service-mainheading {
  text-align: center;
  font: normal normal bold 26px/66px Poppins;
  letter-spacing: 0px;
  color: #3167ba;
  text-transform: capitalize;
  opacity: 1;
}
.service-mainheading1 {
  text-align: left;
  font: normal normal bold 26px/66px Poppins;
  letter-spacing: 0px;
  color: #3167ba;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 25px;
}
.service-mainheading2 {
  text-align: left !important;
  font: normal normal bold 23px/24px Poppins;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  color: #484747;
}

.resp_width {
  width: 78%;
}
.bg-card {
  background-color: #f8f8f8;
    margin: 15px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    border-radius: 10px;
    padding: 20px;
    min-height: 245px;
}

.service6 .bg-card {
  min-height: 275px !important;
}
.processcard.bg-card {
    min-height: 257px;
}
.bg-card1 {
  background-color: #f8f8f8;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  padding: 10px 20px;
  min-height: 475px;
  text-align: center;
}
.bg-card2 {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px 20px;
  min-height: 120px;
}

.bg-card3 {
  background-color: #f3f3f3;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.bg-card3 + p {
  font: normal normal 500 14px/20px Poppins;
    letter-spacing: 0.8px;
    color: #b1b1b1;
}
.card-heading {
  /* text-align: left; */
  font: normal normal 600 18px/40px Poppins;
  letter-spacing: 0px;
  color: #3167ba;
  opacity: 1;
  line-height: 1.5;
}

.card-heading2 {
  text-align: left;
  font: normal normal 500 14px/40px Poppins;
  letter-spacing: 0px;
  opacity: 1;
  line-height: 1.5 !important;
}
.card-paragraph {
  font: normal normal 500 14px/30px Poppins;
  color: #5b5b5b;
  opacity: 1;
}
.service-cardimg {
  width: fit-content;
  height: 80px;
}

.services p {
  margin: 0;
}
.service-cardimg1 {
  width: 80px;
  align-self: center;
  margin-bottom: 20px;
}

.service-cardimg2 {
  align-self: end;
  height: 60px;
}

.service-cardimg3 {
  width: 40px;
}

.service-explore {
  font: normal normal bold 15px/30px Poppins;
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  cursor: pointer;
}


.service-Contactinfo {
  opacity: 1;
  z-index: -1;
  display: block !important;
}
.call-icon {
  position: relative;
  left: 27%;
  width: 80px;
}
.msg-icon {
  position: absolute;
  left: 29px;
  top: -40px;
}

.service-contact {
  text-align: center;
  font: normal normal 500 17px/38px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  display: inline-block;
}

.service-Contactinfo h4 {
  padding-top: 20px;
}

@media screen and (max-width: 1730px) {
  .service3 .processcard h6 {
    font-size: 14px;
  }
  .service6 .processcard .card-paragraph {
    font: normal normal 500 11px/20px Poppins !important;
  }
  .service3 .processcard .card-paragraph {
    font: normal normal 500 10px/20px Poppins !important;
  }
  .wp-caption-text {
    min-height: 93px;
  }
  .success .aboutKode1 {
    font-size: 25px;
}
.success .aboutHead1 {
  font: normal normal 500 20px/25px Poppins;
}
.success .card-paragraph {
  font: normal normal 500 13px/25px Poppins;
}
.support .card-paragraph {
  font: normal normal 500 11px/20px Poppins !important;
}
}

@media screen and (max-width: 1635px) {
  .service5 .card-paragraph, .service6 .card-paragraph {
    font: normal normal 500 13px/30px Poppins;
}
.service6 .processcard .card-paragraph {
  font: normal normal 500 10px/20px Poppins !important;
}
.service6 .processcard h6 {
  font-size: 14px !important;
}
}

@media screen and (max-width: 1531px) {
  .service2 .card-paragraph {
    font: normal normal 500 13px/30px Poppins !important;
  }
  .service5 .card-paragraph {
    font: normal normal 500 12px/30px Poppins;
}
}

@media screen and (max-width: 1500px) {
  .service4 .card-paragraph {
    font: normal normal 500 13px/30px Poppins;
}
  .service3 .processcard.bg-card {
    min-height: 255px !important;
  }
  .service6 .processcard.bg-card {
    min-height: 275px !important;
  }
  .service2 .card-paragraph, .service6 .card-paragraph {
    font: normal normal 500 12px/25px Poppins !important;
  }
  .service2 .bg-card {
    min-height: 230px !important;
}
  .callus1 img {
    width: 28px !important;
}
.callus1 {
  font-size: 11px !important;
}
  .process-cardimg {
    width: 50px !important;
  }
  .process-card {
    width: 90px !important;
    height: 90px !important;
  }
  .success .aboutHead1 {
    font: normal normal 500 16px/25px Poppins;
  }
  .success .aboutKode1 {
    font-size: 21px;
  }
  .success .card-paragraph {
    font: normal normal 500 12px/23px Poppins;
  }
  .wp-caption-text, .service2 .wp-caption-text {
    min-height: 80px !important;
    font-size: 9px !important;
  }
  .wp-caption-text h6 {
    font-size: 13px;
  }
  .service-Contactinfo h4 {
    font-size: 17px;
  }
  .techstackTabs .heading2 {
    font-size: clamp(30px, 5vw, 40px);
  }
  .techstackTabs .sub-head {
    font-size: 17px !important;
  }
  .techstackTabs .nav-link {
    font-size: 19px !important;
  }
  .techstackTabs .service-cardimg2 {
    height: 100px !important;
  }
}

@media screen and (max-width: 1425px) {
  .service4 .processcard h6, .service5 .processcard h6 {
    font-size: 14px !important;
  }
  .service5 .processcard .card-paragraph {
    font: normal normal 500 10px/18px Poppins !important;
  }
  .service6 .services .card-paragraph {
    font: normal normal 500 11px/25px Poppins !important;
  }
  .service4 .processcard.bg-card {
    min-height: 246px !important;
  }
  .service4 .services .bg-card {
    min-height: 258px;
}
.service5 .services .bg-card {
  min-height: 288px !important;
}
  .service2 .bg-card {
    min-height: 268px !important;
}
  .support_img2 {
    height: 83px !important;
}
.support_img {
  width: 120px !important;
}
.support {
  padding: 15px !important;
}
.support .card-paragraph {
  font: normal normal 500 10px/15px Poppins !important;
}
.callus1 img {
  width: 23px !important;
  margin-top: -16px !important;
}
.callus1 {
  font-size: 10px !important;
}
  .processcard.bg-card {
    min-height: 230px !important;
  }
  .service3 .processcard.bg-card {
    min-height: 255px !important;
  }
  .service3 .processcard h6 {
    font-size: 12px !important;
    text-align: center;
}

  .processcard .card-paragraph {
    font: normal normal 500 11px/18px Poppins !important;
  }
  
  .success .aboutHead1 {
    font: normal normal 500 14px/25px Poppins;
  }
  .success .aboutKode1 {
    font-size: 18px;
  }
  .success .card-paragraph {
    font: normal normal 500 11px/20px Poppins;
  }
    .bg-card {
      min-height: 250px;
  }
  .services .card-paragraph {
    font: normal normal 500 12px/30px Poppins;
  }
  .card-heading {
    font: normal normal 600 16px/40px Poppins;
  }
}

@media screen and (max-width: 1300px) {
  .service2 .wp-caption-text {
    min-height: 91px !important;
  }
  .service5 .processcard h6 {
    font-size: 13px !important;
  }
  .service2 .bg-card {
    min-height: 243px !important;
}
  .success img {
    width: 115% !important;
}
.success + .aboutHead1 {
  font: normal normal 500 20px/36px Poppins;
}
.success + .aboutHead1 .aboutKode1 {
  font-size: 27px;
}
.processcard.bg-card {
  min-height: 250px !important;
}
.support .card-paragraph {
  font: normal normal 500 9px/15px Poppins !important;
}
  .achieveImg {
    width: 35%;
    padding-top: 30px;
    height: 20vh;
    margin-bottom: 2%;
  }
  .card-heading2 {
    font: normal normal 500 13px/40px Poppins !important;
  }
  .card-heading {
    font: normal normal 600 14px/40px Poppins;
}
  .bg-card, .service5 .services .bg-card {
    min-height: 243px !important;
  }
  .services .card-paragraph {
    font: normal normal 500 11px/25px Poppins;
}
  .bg-card2 {
    min-height: 125px !important;
  }
  .service-cardimg {
    height: 55px;
}
  .techstackTabs .heading2 {
    font-size: clamp(30px, 5vw, 32px) !important;
}
.techstackTabs .nav-link {
  font-size: 17px !important;
}
.techstackTabs .service-cardimg2 {
  height: 85px !important;
}
}

@media screen and (max-width: 1260px) {
  .service5 .processcard h6 {
    font-size: 12px !important;
  }
  .service4 .processcard h6 {
    font-size: 13px !important;
  }
  .service3 .processcard.bg-card {
    min-height: 272px !important;
  }
}

@media (min-width: 769px) and (max-width: 1199px) {
  .service6 .processcard.bg-card {
    min-height: 255px !important;
  }
  .image {
    width: 120px !important;
}
.button-item, #dropdown-basic {
  font: normal normal 600 14px/27px Poppins;
}
.menu-items {
  width: 72%;
}
  .service3 .processcard.bg-card {
    min-height: 232px !important;
  }
  .service2 .wp-caption-text {
    min-height: 80px !important;
  }
  .service2 .bg-card {
    min-height: 223px !important;
}
  .callus1 {
    font-size: 9px !important;
    margin-left: 0px !important;
    padding: 10px 5px !important;
    margin-top: 5px;
}
.support {
  flex-direction: column;
  align-items: start;
}
.support_img {
  right: 0 !important;
  left: auto !important;
}
.support_img2 {
  height: 135px !important;
  bottom: 0;
}
  .success .aboutKode1 {
    font-size: 16px;
}
.processcard.bg-card {
  min-height: 220px !important;
}
.success .aboutHead1 {
  font: normal normal 500 12px/25px Poppins;
}
.success {
  width: 70% !important;
}
.success .card-paragraph {
  font: normal normal 500 10px/18px Poppins;
}
  .serv_arrows {
    font-size: 29px !important;
}
  .service-Contactinfo h4 {
    font-size: 14px;
  }
  .card-paragraph {
    font: normal normal 500 14px/25px Poppins;
  }
  .services .card-paragraph {
    font: normal normal 500 10px/25px Poppins;
}
  .bg-card, .service3 .bg-card {
    min-height: 205px !important;
  }
  .service5 .services .bg-card {
    min-height: 223px !important;
  }
  .bg-card2 {
    padding: 10px !important;
  }
  .card-heading {
    font: normal normal 600 13px/20px Poppins;
}
  .contact-bg-cont {
    height: 40vh !important;
  }
  .welcome {
    height: 40vh !important;
  }
  .aboutVideo {
    height: 40vh !important;
  }
  .welContent {
    top: 10vh;
    z-index: 8;
    padding: 0px 20px;
  }
  .aboutHead {
    font: normal normal 500 23px/26px Poppins;
  }
  .aboutKode {
    font-size: 30px;
}
.serv_subhead {
  font-size: 20px;
}
  .aboutPara {
    font: normal normal normal 12px/22px Poppins;
  }
  .form-head {
    font: normal normal 600 26px/30px Poppins !important;
  }
  .form-para {
    font: normal normal 500 14px/25px Poppins !important;
  }
  .aboutKode1 {
    font-size: 26px;
}
.aboutHead1 {
  font: normal normal 500 22px/36px Poppins;
}
.success + .aboutHead1 .aboutKode1 {
  font-size: 26px;
}
.success + .aboutHead1 {
font: normal normal 500 22px/36px Poppins;
}
}
@media (max-width: 1067px) {
  .service6 .processcard.bg-card {
    min-height: 274px !important;
  }
  .service6 .success .card-paragraph {
    font: normal normal 500 11px/25px Poppins !important;
}
  .service5 .services .bg-card {
    min-height: 243px !important;
}
  .service4 .services .bg-card {
    min-height: 225px !important;
}
  .service3 .processcard.bg-card {
    min-height: 252px !important;
  }
  .service2 .bg-card {
    min-height: 248px !important;
}
  .bg-card {
    min-height: 240px !important;
}
.success img {
  width: 120% !important;
}
.processcard.bg-card {
  min-height: 230px !important;
}
}

@media screen and (max-width: 992px) {
  .service2 .bg-card {
    min-height: 225px !important;
}
.service6 .success img {
  width: 130% !important;
}
.service2 .wp-caption-text {
  min-height: 80px !important;
}
  .support {
    flex-direction: column;
    align-items: start;
    width: 70% !important;
}
.callus1 {
  font-size: 9px !important;
  margin-left: 0px !important;
  padding: 10px 5px !important;
  margin-top: 5px;
}
.support_img {
  right: 0 !important;
  left: auto !important;
}
.support_img2 {
  height: 135px !important;
  bottom: 0;
}
  .success .card-paragraph {
    font: normal normal 500 9px/18px Poppins;
}
  .wp-caption-text h6 {
    font-size: 12px;
  }
  .card-carousel {
    padding: 10px;
    margin-left: 20px;
  }
  .service-Contactinfo h4 {
    padding-top: 10px !important;
  }
  .msg-icon {
    top: -25px !important;
    width: 60px;
  }
  .service-Contactinfo {
    margin-top: 10px;
  }
  .client-testimonial {
    width: 100%;
  }
  .client-testimonial1 {
    width: 100%;
  }

  .carousel-img {
    width: 50%;
  }
  .client {
    width: 94%;
    margin: 5px;
    height: 40vh;
  }
  .content-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 25px; */
  }
  .resp_width {
    width: 70% !important;
  }
  .card-1 {
    padding-top: 10%;
  }
  .techstackTabs .tab-content {
    margin-bottom: 0 !important;
}
.techstackTabs .service-cardimg2 {
  height: 75px !important;
}
.link {
  margin-top: 10px !important;
}
.techstackTabs .heading2 {
  font-size: clamp(30px, 5vw, 30px) !important;
}
.techstackTabs .sub-head {
  font-size: 16px !important;
}
.card-paragraph {
  font: normal normal 500 12px/25px Poppins;
}
}
@media (max-width: 991px) {
  .bg-card, .service3 .bg-card, .service4 .services .bg-card, .service5 .services .bg-card {
    min-height: 200px !important;
}
.aboutHead1, .success + .aboutHead1 {
  font: normal normal 500 17px/36px Poppins;
}
.aboutKode1, .success + .aboutHead1 .aboutKode1 {
  font-size: 23px;
}
.service6 .bg-card {
  min-height: 270px !important;
}
}

@media (max-width: 890px) {
  .service5 .services .bg-card {
    min-height: 223px !important;
  }
  .service6 .services .card-paragraph {
    font: normal normal 500 10px/25px Poppins !important;
}
.service6 .success .card-paragraph {
  font: normal normal 500 11px/20px Poppins !important;
}
  .kc_slider {
    width: 80% !important;
  }
  .success img {
    width: 130% !important;
}
.success {
  width: 80% !important;
}
}

@media (max-width: 840px) {
  .service4 .services .card-heading {
    font: normal normal 600 12px/20px Poppins;
}
.service4 .services .bg-card {
  min-height: 223px !important;
}
}

@media (max-width: 800px) {
  .service2 .bg-card {
    min-height: 247px !important;
}
  .bg-card {
    min-height: 243px !important;
}
.kc_slider {
  width: 85% !important;
}
.service6 .services .card-paragraph {
  font: normal normal 500 10px/21px Poppins !important;
}
}

@media (min-width: 320px) and (max-width: 768px) {
  .menu-items .dropdown-item {
    padding: 5px 10px;
    font-size: 12px;
}
  .service5 .services .bg-card {
    min-height: 268px !important;
}
  .success .card-paragraph {
    font: normal normal 500 10px/18px Poppins;
}
.service3 .services .bg-card {
  min-height: 245px !important;
}
.service3 .processcard.bg-card {
  min-height: 252px !important;
}
.support {
  width: 80% !important;
}
  
  .aboutVideo,
  .welcome {
    height: 30vh !important;
    width: 100%;
  }
  .anni-img {
    width: 90px;
  }
  .welContent {
    top: 6vh;
  }
  .aboutHead {
    font: normal normal 500 12px/10px Poppins;
    letter-spacing: 0px;
  }
  .aboutKode {
    font-size: 16px;
  }
  .serv_subhead {
    font-size: 14px;
}
  .aboutPara {
    font: normal normal normal 9px/14px Poppins;
    letter-spacing: 0.24px;
    margin: 0 !important;
  }
  .horizon {
    width: 29%;
    height: 2px;
  }
  .aboutDriven {
    flex-direction: column;
    justify-content: space-evenly;
    padding-top: 10px;
  }
  .drivenHead {
    font: normal normal 600 10px/19px Poppins;
    letter-spacing: 0px;
    color: #575f63;
    text-align: center;
  }
  .drivenSpan {
    font: normal normal 800 14px/19px Poppins;
    letter-spacing: 0px;
    color: #14658b;
  }
  .achieveImg {
    width: 70%;
    height: 20vh;
    padding-top: 5px;
    margin-bottom: 10px;
  }
  .templateHead {
    font: normal normal 600 10px/10px Poppins;
    letter-spacing: 0px;
    color: #575f63;
  }
  .templatePara {
    font: normal normal 600 8px/12px Poppins;
    letter-spacing: 0.32px;
    color: #5b5b5b;
    text-align: center;
  }
  .codeImg {
    height: 22vh;
    padding-top: 0px;
    border-radius: 3px;
  }
  .decade {
    padding: 10px 0px;
  }
  .seperate {
    display: none;
  }
  .goalPara {
    font: normal normal 600 8px/14px Poppins;
    letter-spacing: 0px;
    color: #808080;
    text-align: center;
  }
  .goalPara1 {
    font: normal normal normal 8px/16px Poppins;
    letter-spacing: 0px;
    color: #909090;
    text-align: center;
    padding: 0px 5px;
    width: 100%;
  }
  .goalContent {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .gallarypics {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .gallary-img {
    width: 80%;
  }
  .year {
    font-size: 14px;
    margin-left: 0px;
  }
  .gallary {
    color: #007db8;
  }
  .wrapper1 {
    position: absolute;
    /* z-index: 70; */
    height: 100%;
    width: 104%;
    top: -7px;
    left: -14px;
  }
  .menu-items {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .header {
    justify-content: space-around;
    width: 100%;
  }
  .image {
    width: 58px;
  }
  .button-item, #dropdown-basic {
    font: normal normal 600 8px/9px Poppins;
    letter-spacing: 0px;
    color: #1b2336;
    opacity: 1;
  }
  .main-header {
    /* background-color: red; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 4vh;
    box-shadow: 0px 3px 6px #00000029;
    align-items: center;
  }
  .event-btn {
    font: normal normal 500 8px/30px Poppins;
    width: 152px;
    height: 32px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  .service2 .bg-card, .service3 .bg-card, .service3 .services .bg-card {
    min-height: fit-content !important;
}
  .success img, .service6 .success img {
    width: 50% !important;
}
.success div:first-child {
  text-align: center;
}
  .bg-card, .service4 .services .bg-card, .service5 .services .bg-card, .service6 .services .bg-card {
    min-height: fit-content !important;
}
.aboutHead1, .success + .aboutHead1 {
  font: normal normal 500 15px/36px Poppins;
}
.aboutKode1, .success + .aboutHead1 .aboutKode1 {
  font-size: 20px;
}
}

@media only screen and (min-width: 700px) {
  .p {
    font-size: 2rem;
  };
}
@media screen and (max-width: 700px) {
  .success img {
    width: 60% !important;
}
.processcard.bg-card {
  min-height: 245px !important;
}
.support {
  padding-right: 150px !important;
}
}
@media screen and (max-width: 659px) {
  .support_img2 {
    height: 150px !important;
}
}
@media screen and (max-width: 594px) {
  .service6 .processcard h6 {
    font-size: 13px !important;
  }
  .service4 .processcard .card-paragraph {
    font: normal normal 500 10px/18px Poppins !important;
  }
  .service4 .processcard.bg-card {
    min-height: 262px !important;
  }
  .support_img2 {
    height: 165px !important;
}
.service3 .processcard.bg-card {
  min-height: 273px !important;
}
}

@media screen and (max-width: 549px) {
  .service3 .processcard.bg-card {
    min-height: 287px !important;
  }
  .service6 .processcard.bg-card {
    min-height: 300px !important;
  }
  .success img {
    width: 70% !important;
}
  .kc-item {
    padding: 5px !important;
  }
  .kc_slider {
    width: 90% !important;
  }
  .welContent {
    top: 6vh;
}
.serv_subhead {
  font-size: 12px;
}
.aboutKode {
  font-size: 14px;
}
.aboutHead {
  font: normal normal 500 10px/10px Poppins;
}
}
@media only screen and (min-width: 500px) {
  .p {
    font-size: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .service6 .processcard.bg-card {
    min-height: 275px !important;
  }
  .service4 .processcard.bg-card {
    min-height: 235px !important;
  }
  .service3 .processcard.bg-card {
    min-height: 252px !important;
  }
  .support {
    padding-right: 20px !important;
}
.support_img, .support_img2 {
  display: none !important;
}
  .processcard.bg-card {
    min-height: 229px !important;
  }
  .kc_slider {
    width: 60% !important;
  }
  .success {
    width: 65% !important;
}
.success img, .service6 .success img {
    width: 100% !important;
}
}

@media screen and (max-width: 435px) {
  .kc_slider {
    width: 75% !important;
  }
  .processcard.bg-card {
    min-height: 245px !important;
  }
  .success {
    width: 75% !important;
}
  .welContent {
    top: 5vh;
}
.serv_subhead {
  font-size: 11px;
}
.aboutKode {
  font-size: 12px;
}
.aboutHead {
  font: normal normal 500 9px/10px Poppins;
}
.horizon {
  margin-bottom: 10px;
}
}




@media (max-width: 769px) {
  .services + h1 + p {
    margin-left: 50px;
    margin-right: 50px;
    font-size: 11px;
}
  html {
    background-color: transparent;
  }
  .page-tab {
    display: none;
  }
  .scrollbar {
    display: none;
  }
  .logo {
    width: 110px;
    filter: contrast(1.25rem);
  }
  .menu-logo {
    width: 30px;
    cursor: pointer;
  }
  .nav-cont {
    height: 98%;
    width: 95%;
  }
  .navbar-cont {
    top: 3.5%;
  }
  .explore-btn {
    margin-top: 10px;
  }
  
  .about-content {
    width: 80%;
    /* margin-left: 0%; */
    align-items: center;
    margin-top: 2%;
    text-align: center;
  }
  .about-head {
    text-align: center;
    width: 105%;
  }
  .about-para {
    text-align: center;
  }
  .btn_sec_2,
  .btn_sec_3,
  .btn_sec_5 {
    align-self: center;
  }
  .slickArrow {
    color: white;
    font-size: 35px;
  }
  .btn_sec_3 {
    margin-bottom: 3%;
  }
  .deliever-content {
    margin-right: 0px;
  }

  .service-img {
    width: 85%;
  }
  .service-para {
    width: 95%;
    text-align: center;
  }
  .last-content {
    justify-content: flex-start;
  }
  .techstackTabs .heading2 {
    font-size: clamp(25px, 5vw, 25px) !important;
}
.techstackTabs .nav-link {
  font-size: 15px !important;
  padding: 0 9px;
}
.techstackTabs .service-cardimg2 {
  height: 60px !important;
}
.stackimgs {
  width: 64%;
}
.bg-card3 {
  width: 80px;
  height: 80px;
  margin-bottom: 0 !important;
}
.techstackTabs .nav-link {
  font-size: 14px !important;
  padding: 0 7px;
}
.techstackTabs .sub-head {
  font-size: 15px !important;
}
.techstackTabs .heading2 {
font-size: clamp(23px, 5vw, 22px) !important;
margin-top: 50px;
}
.bg-card3 + p {
margin-bottom: 0;
}
.card-heading {
  font: normal normal 600 14px/20px Poppins;
}
}


@media screen and (max-width: 576px) {
  .service3 .services + .aboutHead1, .service4 .services + .aboutHead1, .service5 .services + .aboutHead1 {
    margin-left: 20px !important;
    margin-right: 20px !important;
    text-align: center;
  }
  .msg-icon {
    left: -10px;
    top: -22px !important;
    width: 45px;
  }
  .services + h1 + p {
    font: normal normal 500 10px/20px Poppins;
    margin-left: 20px;
    margin-right: 20px;
}
  .col-xs-6 {
    width: 50% !important;
  }
  .techstackTabs .heading2 {
    font-size: clamp(20px, 5vw, 22px) !important;
}
.techstackTabs .service-cardimg2 {
  height: 50px !important;
}
.aboutHead1, .success + .aboutHead1 {
  font: normal normal 500 13px/36px Poppins;
}
.aboutKode1, .success + .aboutHead1 .aboutKode1 {
  font-size: 17px;
}
}
@media screen and (max-width: 480px) {
  
  .service-mainheading,
  .service-mainheading1 {
    font: normal normal bold 20px/50px Poppins;
  }
  .service-mainheading2 {
    font: normal normal bold 17px/24px Poppins;
  }
  .service-explore {
    font: normal normal bold 12px/30px Poppins;
    letter-spacing: 0px;
    color: #575757;
    opacity: 1;
  }
  .aboutKode1_2, .success + .aboutHead1 .aboutKode1_2 {
    display: block;;
}
.aboutHead1, .success + .aboutHead1 {
  font: normal normal 500 13px/20px Poppins;
}
}
@media screen and (max-width: 425px) {
  .service-Contactinfo h4 {
    font-size: 10px !important;
  }
  .menu-items {
    width: 67% !important;
}
  .techstackTabs .nav-link {
    padding: 5px 9px 0;
  }
  .techstackTabs .tab-content p {
    margin-bottom: 0;
}
.aboutHead1, .success + .aboutHead1 {
  font: normal normal 500 11px/20px Poppins;
}
.aboutKode1, .success + .aboutHead1 .aboutKode1 {
  font-size: 16px;
}
}
@media screen and (max-width: 375px) {
  .kc_slider {
    width: 80% !important;
  }
  .techstackTabs .sub-head {
    font-size: 14px !important;
}
.aboutHead1_2, .success + .aboutHead1_2 {
  margin-left: 43px;
}
.service3 .aboutHead1_2, .service4 .aboutHead1_2, .service5 .aboutHead1_2, .service6 .aboutHead1_2 {
  margin-left: 0 !important;
}
.service3 .success + .aboutHead1_2 {
  margin-left: 30px !important;
}
.service4 .processcard.bg-card {
  min-height: 245px !important;
}
}
@media screen and (max-width: 325px) {
  .service-Contactinfo h4 {
    margin: 0 !important;
    display: block;
  }
  h4.service-numberinfo {
    margin-bottom: 10px !important;
  }
}
.service-numberinfo {
  text-align: center;
  font: normal normal 600 22px/38px Poppins;
  letter-spacing: 0px;
  color: #14658b;
  margin-left: 10px;
  display: inline-block;
}

/*----------contact------------*/
.contact-bg-cont {
  background-image: url("../assets/img/contactBg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 65vh;
}
.contact {
  padding-top: 50px;
}
.contact-element {
  width: 80%;
  margin-top: 20px;
}

.mobile-contact {
  background-color: #8fc1e2 !important;
  text-align: center;
}
.contact-msg {
  display: flex;
  justify-content: center;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  top: 45%;
}

.form-bg-cont {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 80%;
  /* height: 65vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 15px 0px;
  padding: 25px;
  position: relative;
}
.form-head {
  font: normal normal 600 38px/66px Poppins;
  letter-spacing: 0px;
  color: #007db8;
}
.form-para {
  font: normal normal 500 20px/30px Poppins;
  letter-spacing: 0px;
  color: #1c1856;
}
.contact-input {
  /* width: 580px; */
  width: 100%;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #0000001a;
  border-radius: 4px;
  opacity: 1;
  border: 0px solid white;
  padding-left: 10px;
  font: normal normal normal 14px/14px Poppins;
  letter-spacing: 0.42px;
  color: #02194d;
  opacity: 1;
  outline: none;
  /* border: 1px solid #0000001a; */
}
.input-msg {
  padding-top: 10px;
  width: 100%;
  height: 132px;
}
.send-btn {
  background: #007db8 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  width: 205px;
  height: 50px;
  font: normal normal 500 18px/27px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  border: 0px solid white;
  align-self: flex-start;
  margin: 15px 0px;
}
.input-column {
  display: flex;
  gap: 10px;
  width: 100%;
}
.map {
  height: 650px;
  width: 80%;
  border: 20px solid #f8f8f8;
  margin-bottom: 40px;
}
.line {
  width: 40px;
}
.form-set {
  width: 100%;
}

@media (min-width: 320px) and (max-width: 768px) {
  .line {
    width: 25px;
  }
  .listText,
  .contact-bg-paragraph {
    font: normal normal normal 12px/25px Poppins;
  }
  .contact-bg-cont {
    height: 30vh;
  }
  .contact {
    padding-top: 20px;
  }

  .service-contact {
    font: normal normal 600 10px/10px Poppins;
  }
  .service-numberinfo {
    font: normal normal 600 12px/10px Poppins;
  }

  .msg-icon {
    left: 0px;
    top: -46px;
  }

  .service-Contactinfo h4 {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 12px;
  }
  .form-head {
    font: normal normal bold 12px/20px Poppins;
  }
  .form-para {
    font: normal normal 500 8px/15px Poppins;
  }
  .contact-input {
    /* width: 80%; */
    height: 27px;
    font: normal normal normal 10px/7px Poppins;
    letter-spacing: 0.3px;
  }
  .input-column {
    flex-direction: column;
  }
  .send-btn {
    width: 96px;
    height: 23px;
    font: normal normal 500 11px/17px Poppins;
    border-radius: 3px;
  }
  .input-msg {
    /* width: 80%; */
    height: 164px;
  }
  .form-bg-cont {
    align-items: center;
    padding: 23px;
  }
  .send-btn {
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* left: 8%; */
  }
  .map {
    /* width: 302px; */
    height: 252px;
    border: 5px solid #f8f8f8 !important;
  }
  .form-set {
    padding-right: 6%;
  }
  .openMenu {
    height: 448px !important;
    left: calc(100% - 390px) !important;
    transition: 1s;
    width: 390px !important;
  }
}

/*----------contact------------*/

/* sub-service-page */
.serviceContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  padding: 20px 0px;
}
.sub-service-page {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 95%;
  height: 100%;
  padding: 2%;
  padding-top: 82px;
  border-radius: 10px;
}
.sub-service-main-heading {
  text-align: left;
  font: normal normal bold 32px/55px Poppins;
  letter-spacing: 0px;
  color: #3167ba;
  text-transform: capitalize;
  opacity: 1;
  line-height: 1;
  margin-bottom: 50px;
}
.sub-service-heading {
  text-align: left;
  font: normal normal 600 18px/32px Poppins;
  letter-spacing: 0px;
  font-size: clamp(14px, 1.5vw, 26px);
  color: #909090;
  opacity: 1;
  margin-top: 15px;
}
.sub-service-paragraph {
  font: normal normal normal 14px/36px Poppins;
  letter-spacing: 0px;
  /* font-size: clamp(10px, 1.5vw, 15px); */
  color: #909090;
  opacity: 1;
  line-height: 1.5;
}

.sub-service-card {
  background: #f8f8f8 0% no-repeat padding-box;
  box-shadow: 0px 6px 3px #00000008;
  opacity: 1;
  margin-left: 2%;
  margin-bottom: 4%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 0px;
  height: 170px;
  width: 165px;
}
.inner-sub-para {
  padding: 3px 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 8px 0px;
}
.cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}
.card-para1 {
  font: normal normal 500 18px/26px Poppins;
  letter-spacing: 0px;
}
.topTri {
  width: 0px;
  height: 0;
  border-style: solid;
  border-width: 175px 175px 0 0;
  border-top-left-radius: 10px;
}
.tag {
  top: 6%;
  left: 0.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 18px/2px poppins;
  transform: rotate(315deg);
}
.subCard-img {
  height: 40px;
  width: 40px;
  margin: 5px;
}
.cardclickstyle {
  background: #007db8 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 3px #00000008;
  opacity: 1;
  margin-bottom: 4%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 3px;
  padding-bottom: 0px;
  height: 170px;
  width: 165px;
  color: #ffffff;
  cursor: pointer;
}
.triangle_left {
  width: 5px;
  height: 5px;
  border-style: solid;
  border-width: 12px 19.6px 12px 0;
  /* border-color: transparent orange transparent transparent; */
}
.sub-service-corner {
  height: 50%;
  opacity: 0.5;
}

.card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
}
.slide {
  margin-left: 23px;
}
.triangle_down {
  display: none;
}
.sub-container {
  height: 73vh;
}
.service-content-card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 84px;
  height: 73vh;
}
.service-sub-img {
  width: 75%;
}
.service-aboutHead {
  font: normal normal 500 30px/36px Poppins;
}
.service-aboutPara {
  font: normal normal normal 18px/30px Poppins;
  letter-spacing: 0.72px;
}
.sub-service-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.horizon {
  display: block;
}

@media screen and (min-width: 1800px) {
  .cardclickstyle {
    width: 220px;
    cursor: pointer;
  }
  .sub-service-card {
    width: 220px;
  }
}
.menu {
  position: fixed;
  height: 100px;
  width: 100px;
  background: transparent;
  border-radius: 0 0 0 100%;
  left: calc(100% - 100px);
  top: 0px;
  transition: 1s;
}
.openMenu {
  height: 500px;
  width: 500px;
  left: calc(100% - 500px);
  transition: 1s;
  border-width: 0px 0px 20px 20px;
  /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), radial-gradient(at top center, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.4) 120%) #989898;
  background-blend-mode: multiply, multiply; */
  background-color: transparent;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 25;
}
.closeMenu {
  color: white;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 30px;
  top: 30px;
  opacity: 0;
  cursor: pointer;
}

.openMenu .closeMenu {
  opacity: 0;
  transition: 2s all ease;
  animation: close;
  animation-delay: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

.menu > nav {
  top: 20%;
  left: 50%;
}
.menu nav {
  width: 72%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-23%, -65%);
  transition: 1s;
}

.openMenu nav {
  width: 72%;
  position: absolute;
  top: 40%;
  left: 65%;
  transform: translate(-50%, -50%);
  transition: 1s;
  /* transition: 2s all ease;
  animation: close;
  animation-delay: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-duration: 1s; */
}
.menu nav ul {
  list-style-type: none;
  font-size: 26px;
}
.menu nav ul li {
  text-transform: uppercase;
}
.menu nav ul li a {
  text-decoration: none;
  color: white;
  line-height: 2em;
  letter-spacing: 0.2em;
  font-weight: 600;
}
.menu nav ul li a:hover {
  color: #dddddd;
}
.wrapper {
  position: absolute;
  z-index: 70;
  min-height: 90vh;
  width: 80%;
}
@keyframes close {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.9;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .serviceContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .sub-service-page {
    padding-top: 34px;
  }
  /* .horizon {
    display: none;
  } */
  .service-content-card {
    height: 15vh;
  }
  .sub-container {
    height: 25vh;
  }
  .service-sub-img {
    width: 80%;
  }
  .service-aboutHead {
    font: normal normal 500 10px/5px Poppins;
    margin: 0 !important;
  }
  .sub-service-main-heading {
    font: normal normal bold 16px/66px Poppins;
    margin-bottom: 0px;
  }
  .service-aboutPara {
    width: 96%;
    font: normal normal normal 8px/0px Poppins;
    letter-spacing: 0.32px;
    margin: 0 !important;
    line-height: 10px;
  }
  .subtile {
    font: normal normal 600 12px/15px Poppins;
  }
  .sub {
    order: 1;
  }
  .card-para1 {
    font: normal normal 500 8px/10px Poppins;
  }
  .card-para {
    font: normal normal 500 13px/18px Poppins;
  }

  .client-head {
    font: normal normal bold 15px/35px Poppins;
  }
  .subCard-img {
    width: 20px;
    height: 20px;
    margin-top: 16%;
  }

  .slide {
    margin-left: 0px;
    margin-bottom: 6%;
  }
  .cards {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    order: 0;
    width: 100%;
  }
  .sub-service-corner {
    height: 50%;
    opacity: 08;
    /* width: 50%; */
  }
  .sub-service-page {
    width: 100%;
  }
  .cardclickstyle {
    width: 94px;
    height: 70px;
    margin-bottom: 0%;
    border-radius: 5px;
    gap: 0px;
    padding: 10px 0px;
    cursor: pointer;
  }
  .triangle_left {
    display: none;
  }
  .triangle_down {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7px 0 8px;
    order: 1;
  }
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    order: 0;
    margin-left: 0%;
    /* margin-right: 1px; */
  }
  .topTri {
    border-width: 110px 110px 0 0;
  }
  .tag {
    top: 36px;
    left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 12px/10px poppins;
    transform: rotate(315deg);
  }
  .sub-service-paragraph {
    text-align: center;
    font: normal normal normal 11px/16px Poppins;
  }
  .inner-sub-para {
    padding: 0px 2px;
  }
  .menu nav ul {
    font-size: 23px;
  }
}

[class|="confetti"] {
  position: absolute;
}

.confetti-0 {
  width: 2px;

  height: 0.8px;

  background-color: #d13447;

  top: -10%;

  left: 97%;

  opacity: 0.8080931284;

  transform: rotate(159.9686803142deg);

  -webkit-animation: drop-0 4.7508618828s 0.134908864s infinite;

  animation: drop-0 4.7508618828s 0.134908864s infinite;
}

@-webkit-keyframes drop-0 {
  100% {
    top: 110%;

    left: 101%;
  }
}

@keyframes drop-0 {
  100% {
    top: 110%;

    left: 101%;
  }
}

.confetti-1 {
  width: 8px;

  height: 3.2px;

  background-color: #d13447;

  top: -10%;

  left: 82%;

  opacity: 1.256784606;

  transform: rotate(248.025638851deg);

  -webkit-animation: drop-1 4.9671166569s 0.8886353544s infinite;

  animation: drop-1 4.9671166569s 0.8886353544s infinite;
}

@-webkit-keyframes drop-1 {
  100% {
    top: 110%;

    left: 95%;
  }
}

@keyframes drop-1 {
  100% {
    top: 110%;

    left: 95%;
  }
}

.confetti-2 {
  width: 6px;

  height: 2.4px;

  background-color: #ffbf00;

  top: -10%;

  left: 6%;

  opacity: 0.6130271004;

  transform: rotate(272.8770986805deg);

  -webkit-animation: drop-2 4.3973554053s 0.9938327492s infinite;

  animation: drop-2 4.3973554053s 0.9938327492s infinite;
}

@-webkit-keyframes drop-2 {
  100% {
    top: 110%;

    left: 19%;
  }
}

@keyframes drop-2 {
  100% {
    top: 110%;

    left: 19%;
  }
}

.confetti-3 {
  width: 5px;

  height: 2px;

  background-color: #263672;

  top: -10%;

  left: 27%;

  opacity: 1.3755858319;

  transform: rotate(258.6189313239deg);

  -webkit-animation: drop-3 4.0077225371s 0.3258404608s infinite;

  animation: drop-3 4.0077225371s 0.3258404608s infinite;
}

@-webkit-keyframes drop-3 {
  100% {
    top: 110%;

    left: 37%;
  }
}

@keyframes drop-3 {
  100% {
    top: 110%;

    left: 37%;
  }
}

.confetti-4 {
  width: 3px;

  height: 1.2px;

  background-color: #ffbf00;

  top: -10%;

  left: 57%;

  opacity: 0.7241917747;

  transform: rotate(84.0794658287deg);

  -webkit-animation: drop-4 4.9114428665s 0.9864208913s infinite;

  animation: drop-4 4.9114428665s 0.9864208913s infinite;
}

@-webkit-keyframes drop-4 {
  100% {
    top: 110%;

    left: 66%;
  }
}

@keyframes drop-4 {
  100% {
    top: 110%;

    left: 66%;
  }
}

.confetti-5 {
  width: 2px;

  height: 0.8px;

  background-color: #263672;

  top: -10%;

  left: 19%;

  opacity: 1.4513871661;

  transform: rotate(251.0238610992deg);

  -webkit-animation: drop-5 4.275937084s 0.9062326047s infinite;

  animation: drop-5 4.275937084s 0.9062326047s infinite;
}

@-webkit-keyframes drop-5 {
  100% {
    top: 110%;

    left: 26%;
  }
}

@keyframes drop-5 {
  100% {
    top: 110%;

    left: 26%;
  }
}

.confetti-6 {
  width: 8px;

  height: 3.2px;

  background-color: #263672;

  top: -10%;

  left: 85%;

  opacity: 1.3389045004;

  transform: rotate(41.9272282994deg);

  -webkit-animation: drop-6 4.4493033551s 0.5244718681s infinite;

  animation: drop-6 4.4493033551s 0.5244718681s infinite;
}

@-webkit-keyframes drop-6 {
  100% {
    top: 110%;

    left: 95%;
  }
}

@keyframes drop-6 {
  100% {
    top: 110%;

    left: 95%;
  }
}

.confetti-7 {
  width: 8px;

  height: 3.2px;

  background-color: #263672;

  top: -10%;

  left: 9%;

  opacity: 0.7037141281;

  transform: rotate(90.8474369519deg);

  -webkit-animation: drop-7 4.7928601197s 0.3272390187s infinite;

  animation: drop-7 4.7928601197s 0.3272390187s infinite;
}

@-webkit-keyframes drop-7 {
  100% {
    top: 110%;

    left: 17%;
  }
}

@keyframes drop-7 {
  100% {
    top: 110%;

    left: 17%;
  }
}

.confetti-8 {
  width: 7px;

  height: 2.8px;

  background-color: #263672;

  top: -10%;

  left: 77%;

  opacity: 0.6041980849;

  transform: rotate(297.4007620377deg);

  -webkit-animation: drop-8 4.768806702s 0.2943008379s infinite;

  animation: drop-8 4.768806702s 0.2943008379s infinite;
}

@-webkit-keyframes drop-8 {
  100% {
    top: 110%;

    left: 85%;
  }
}

@keyframes drop-8 {
  100% {
    top: 110%;

    left: 85%;
  }
}

.confetti-9 {
  width: 2px;

  height: 0.8px;

  background-color: #ffbf00;

  top: -10%;

  left: 19%;

  opacity: 0.867085844;

  transform: rotate(231.4600725308deg);

  -webkit-animation: drop-9 4.6166372768s 0.0533035048s infinite;

  animation: drop-9 4.6166372768s 0.0533035048s infinite;
}

@-webkit-keyframes drop-9 {
  100% {
    top: 110%;

    left: 28%;
  }
}

@keyframes drop-9 {
  100% {
    top: 110%;

    left: 28%;
  }
}

.confetti-10 {
  width: 1px;

  height: 0.4px;

  background-color: #ffbf00;

  top: -10%;

  left: 84%;

  opacity: 1.0135030283;

  transform: rotate(124.9881808399deg);

  -webkit-animation: drop-10 4.729084537s 0.976035727s infinite;

  animation: drop-10 4.729084537s 0.976035727s infinite;
}

@-webkit-keyframes drop-10 {
  100% {
    top: 110%;

    left: 89%;
  }
}

@keyframes drop-10 {
  100% {
    top: 110%;

    left: 89%;
  }
}

.confetti-11 {
  width: 5px;

  height: 2px;

  background-color: #263672;

  top: -10%;

  left: 28%;

  opacity: 0.8972258904;

  transform: rotate(142.0897802028deg);

  -webkit-animation: drop-11 4.0911412351s 0.0815955885s infinite;

  animation: drop-11 4.0911412351s 0.0815955885s infinite;
}

@-webkit-keyframes drop-11 {
  100% {
    top: 110%;

    left: 33%;
  }
}

@keyframes drop-11 {
  100% {
    top: 110%;

    left: 33%;
  }
}

.confetti-12 {
  width: 8px;

  height: 3.2px;

  background-color: #263672;

  top: -10%;

  left: 80%;

  opacity: 0.5587772774;

  transform: rotate(168.40876995deg);

  -webkit-animation: drop-12 4.8897827393s 0.1152157232s infinite;

  animation: drop-12 4.8897827393s 0.1152157232s infinite;
}

@-webkit-keyframes drop-12 {
  100% {
    top: 110%;

    left: 81%;
  }
}

@keyframes drop-12 {
  100% {
    top: 110%;

    left: 81%;
  }
}

.confetti-13 {
  width: 6px;

  height: 2.4px;

  background-color: #ffbf00;

  top: -10%;

  left: 27%;

  opacity: 0.7077455543;

  transform: rotate(348.8085936685deg);

  -webkit-animation: drop-13 4.1637028329s 0.2322795291s infinite;

  animation: drop-13 4.1637028329s 0.2322795291s infinite;
}

@-webkit-keyframes drop-13 {
  100% {
    top: 110%;

    left: 33%;
  }
}

@keyframes drop-13 {
  100% {
    top: 110%;

    left: 33%;
  }
}

.confetti-14 {
  width: 1px;

  height: 0.4px;

  background-color: #ffbf00;

  top: -10%;

  left: 73%;

  opacity: 0.7060293135;

  transform: rotate(82.9245272945deg);

  -webkit-animation: drop-14 4.8437179149s 0.5696154222s infinite;

  animation: drop-14 4.8437179149s 0.5696154222s infinite;
}

@-webkit-keyframes drop-14 {
  100% {
    top: 110%;

    left: 86%;
  }
}

@keyframes drop-14 {
  100% {
    top: 110%;

    left: 86%;
  }
}

.confetti-15 {
  width: 3px;

  height: 1.2px;

  background-color: #d13447;

  top: -10%;

  left: 82%;

  opacity: 1.4287791989;

  transform: rotate(181.7078663558deg);

  -webkit-animation: drop-15 4.7186506815s 0.9214075642s infinite;

  animation: drop-15 4.7186506815s 0.9214075642s infinite;
}

@-webkit-keyframes drop-15 {
  100% {
    top: 110%;

    left: 83%;
  }
}

@keyframes drop-15 {
  100% {
    top: 110%;

    left: 83%;
  }
}

.confetti-16 {
  width: 7px;

  height: 2.8px;

  background-color: #ffbf00;

  top: -10%;

  left: 22%;

  opacity: 0.5102534884;

  transform: rotate(102.988977722deg);

  -webkit-animation: drop-16 4.8689765436s 0.8135261866s infinite;

  animation: drop-16 4.8689765436s 0.8135261866s infinite;
}

@-webkit-keyframes drop-16 {
  100% {
    top: 110%;

    left: 23%;
  }
}

@keyframes drop-16 {
  100% {
    top: 110%;

    left: 23%;
  }
}

.confetti-17 {
  width: 6px;

  height: 2.4px;

  background-color: #d13447;

  top: -10%;

  left: 74%;

  opacity: 1.3652346088;

  transform: rotate(236.5187942827deg);

  -webkit-animation: drop-17 4.681068046s 0.0713986252s infinite;

  animation: drop-17 4.681068046s 0.0713986252s infinite;
}

@-webkit-keyframes drop-17 {
  100% {
    top: 110%;

    left: 78%;
  }
}

@keyframes drop-17 {
  100% {
    top: 110%;

    left: 78%;
  }
}

.confetti-18 {
  width: 8px;

  height: 3.2px;

  background-color: #d13447;

  top: -10%;

  left: 66%;

  opacity: 1.3307573226;

  transform: rotate(199.4922118027deg);

  -webkit-animation: drop-18 4.4736632259s 0.5016711303s infinite;

  animation: drop-18 4.4736632259s 0.5016711303s infinite;
}

@-webkit-keyframes drop-18 {
  100% {
    top: 110%;

    left: 80%;
  }
}

@keyframes drop-18 {
  100% {
    top: 110%;

    left: 80%;
  }
}

.confetti-19 {
  width: 7px;

  height: 2.8px;

  background-color: #ffbf00;

  top: -10%;

  left: 52%;

  opacity: 0.6002316228;

  transform: rotate(125.8214864984deg);

  -webkit-animation: drop-19 4.1834792412s 0.8821528658s infinite;

  animation: drop-19 4.1834792412s 0.8821528658s infinite;
}

@-webkit-keyframes drop-19 {
  100% {
    top: 110%;

    left: 66%;
  }
}

@keyframes drop-19 {
  100% {
    top: 110%;

    left: 66%;
  }
}

.confetti-20 {
  width: 7px;

  height: 2.8px;

  background-color: #d13447;

  top: -10%;

  left: 5%;

  opacity: 1.3443871994;

  transform: rotate(135.9871352177deg);

  -webkit-animation: drop-20 4.3904516527s 0.5853218541s infinite;

  animation: drop-20 4.3904516527s 0.5853218541s infinite;
}

@-webkit-keyframes drop-20 {
  100% {
    top: 110%;

    left: 19%;
  }
}

@keyframes drop-20 {
  100% {
    top: 110%;

    left: 19%;
  }
}

.confetti-21 {
  width: 2px;

  height: 0.8px;

  background-color: #d13447;

  top: -10%;

  left: 5%;

  opacity: 1.042190587;

  transform: rotate(143.3016573493deg);

  -webkit-animation: drop-21 4.6202538544s 0.643545076s infinite;

  animation: drop-21 4.6202538544s 0.643545076s infinite;
}

@-webkit-keyframes drop-21 {
  100% {
    top: 110%;

    left: 17%;
  }
}

@keyframes drop-21 {
  100% {
    top: 110%;

    left: 17%;
  }
}

.confetti-22 {
  width: 7px;

  height: 2.8px;

  background-color: #d13447;

  top: -10%;

  left: 63%;

  opacity: 0.8818879429;

  transform: rotate(103.5242629195deg);

  -webkit-animation: drop-22 4.1471098726s 0.8245304521s infinite;

  animation: drop-22 4.1471098726s 0.8245304521s infinite;
}

@-webkit-keyframes drop-22 {
  100% {
    top: 110%;

    left: 66%;
  }
}

@keyframes drop-22 {
  100% {
    top: 110%;

    left: 66%;
  }
}

.confetti-23 {
  width: 1px;

  height: 0.4px;

  background-color: #263672;

  top: -10%;

  left: 66%;

  opacity: 0.9642107882;

  transform: rotate(305.1948452522deg);

  -webkit-animation: drop-23 4.0723449873s 0.0047830095s infinite;

  animation: drop-23 4.0723449873s 0.0047830095s infinite;
}

@-webkit-keyframes drop-23 {
  100% {
    top: 110%;

    left: 73%;
  }
}

@keyframes drop-23 {
  100% {
    top: 110%;

    left: 73%;
  }
}

.confetti-24 {
  width: 4px;

  height: 1.6px;

  background-color: #263672;

  top: -10%;

  left: 36%;

  opacity: 1.3453967878;

  transform: rotate(229.8427086615deg);

  -webkit-animation: drop-24 4.466046038s 0.8283207417s infinite;

  animation: drop-24 4.466046038s 0.8283207417s infinite;
}

@-webkit-keyframes drop-24 {
  100% {
    top: 110%;

    left: 37%;
  }
}

@keyframes drop-24 {
  100% {
    top: 110%;

    left: 37%;
  }
}

.confetti-25 {
  width: 2px;

  height: 0.8px;

  background-color: #263672;

  top: -10%;

  left: 10%;

  opacity: 0.9729978349;

  transform: rotate(61.4103770873deg);

  -webkit-animation: drop-25 4.0204691102s 0.3683248092s infinite;

  animation: drop-25 4.0204691102s 0.3683248092s infinite;
}

@-webkit-keyframes drop-25 {
  100% {
    top: 110%;

    left: 17%;
  }
}

@keyframes drop-25 {
  100% {
    top: 110%;

    left: 17%;
  }
}

.confetti-26 {
  width: 8px;

  height: 3.2px;

  background-color: #ffbf00;

  top: -10%;

  left: 52%;

  opacity: 1.305990699;

  transform: rotate(99.6365195331deg);

  -webkit-animation: drop-26 4.7640340678s 0.388960469s infinite;

  animation: drop-26 4.7640340678s 0.388960469s infinite;
}

@-webkit-keyframes drop-26 {
  100% {
    top: 110%;

    left: 54%;
  }
}

@keyframes drop-26 {
  100% {
    top: 110%;

    left: 54%;
  }
}

.confetti-27 {
  width: 6px;

  height: 2.4px;

  background-color: #263672;

  top: -10%;

  left: 80%;

  opacity: 1.101940865;

  transform: rotate(74.8008587793deg);

  -webkit-animation: drop-27 4.9814919626s 0.3793856722s infinite;

  animation: drop-27 4.9814919626s 0.3793856722s infinite;
}

@-webkit-keyframes drop-27 {
  100% {
    top: 110%;

    left: 85%;
  }
}

@keyframes drop-27 {
  100% {
    top: 110%;

    left: 85%;
  }
}

.confetti-28 {
  width: 1px;

  height: 0.4px;

  background-color: #263672;

  top: -10%;

  left: 71%;

  opacity: 1.2010562248;

  transform: rotate(270.1168022197deg);

  -webkit-animation: drop-28 4.3073832085s 0.8136302675s infinite;

  animation: drop-28 4.3073832085s 0.8136302675s infinite;
}

@-webkit-keyframes drop-28 {
  100% {
    top: 110%;

    left: 78%;
  }
}

@keyframes drop-28 {
  100% {
    top: 110%;

    left: 78%;
  }
}

.confetti-29 {
  width: 4px;

  height: 1.6px;

  background-color: #263672;

  top: -10%;

  left: 19%;

  opacity: 0.685766179;

  transform: rotate(46.8871076828deg);

  -webkit-animation: drop-29 4.8818082732s 0.8050519298s infinite;

  animation: drop-29 4.8818082732s 0.8050519298s infinite;
}

@-webkit-keyframes drop-29 {
  100% {
    top: 110%;

    left: 29%;
  }
}

@keyframes drop-29 {
  100% {
    top: 110%;

    left: 29%;
  }
}

.confetti-30 {
  width: 4px;

  height: 1.6px;

  background-color: #ffbf00;

  top: -10%;

  left: 18%;

  opacity: 0.8925937954;

  transform: rotate(142.2238288279deg);

  -webkit-animation: drop-30 4.2973245744s 0.2051776786s infinite;

  animation: drop-30 4.2973245744s 0.2051776786s infinite;
}

@-webkit-keyframes drop-30 {
  100% {
    top: 110%;

    left: 22%;
  }
}

@keyframes drop-30 {
  100% {
    top: 110%;

    left: 22%;
  }
}

.confetti-31 {
  width: 7px;

  height: 2.8px;

  background-color: #d13447;

  top: -10%;

  left: 58%;

  opacity: 1.1127509929;

  transform: rotate(244.4178681607deg);

  -webkit-animation: drop-31 4.3076511025s 0.0891077471s infinite;

  animation: drop-31 4.3076511025s 0.0891077471s infinite;
}

@-webkit-keyframes drop-31 {
  100% {
    top: 110%;

    left: 67%;
  }
}

@keyframes drop-31 {
  100% {
    top: 110%;

    left: 67%;
  }
}

.confetti-32 {
  width: 8px;

  height: 3.2px;

  background-color: #ffbf00;

  top: -10%;

  left: 74%;

  opacity: 0.62946562;

  transform: rotate(226.6354990527deg);

  -webkit-animation: drop-32 4.5541553562s 0.0736066392s infinite;

  animation: drop-32 4.5541553562s 0.0736066392s infinite;
}

@-webkit-keyframes drop-32 {
  100% {
    top: 110%;

    left: 87%;
  }
}

@keyframes drop-32 {
  100% {
    top: 110%;

    left: 87%;
  }
}

.confetti-33 {
  width: 2px;

  height: 0.8px;

  background-color: #263672;

  top: -10%;

  left: 24%;

  opacity: 1.396340549;

  transform: rotate(99.0010410354deg);

  -webkit-animation: drop-33 4.7974768203s 0.8413012461s infinite;

  animation: drop-33 4.7974768203s 0.8413012461s infinite;
}

@-webkit-keyframes drop-33 {
  100% {
    top: 110%;

    left: 32%;
  }
}

@keyframes drop-33 {
  100% {
    top: 110%;

    left: 32%;
  }
}

.confetti-34 {
  width: 7px;

  height: 2.8px;

  background-color: #ffbf00;

  top: -10%;

  left: 57%;

  opacity: 0.7760166293;

  transform: rotate(139.350609408deg);

  -webkit-animation: drop-34 4.6064239436s 0.5008984171s infinite;

  animation: drop-34 4.6064239436s 0.5008984171s infinite;
}

@-webkit-keyframes drop-34 {
  100% {
    top: 110%;

    left: 68%;
  }
}

@keyframes drop-34 {
  100% {
    top: 110%;

    left: 68%;
  }
}

.confetti-35 {
  width: 2px;

  height: 0.8px;

  background-color: #d13447;

  top: -10%;

  left: 12%;

  opacity: 0.9456166994;

  transform: rotate(153.1100248464deg);

  -webkit-animation: drop-35 4.6021394156s 0.7975051677s infinite;

  animation: drop-35 4.6021394156s 0.7975051677s infinite;
}

@-webkit-keyframes drop-35 {
  100% {
    top: 110%;

    left: 19%;
  }
}

@keyframes drop-35 {
  100% {
    top: 110%;

    left: 19%;
  }
}

.confetti-36 {
  width: 6px;

  height: 2.4px;

  background-color: #d13447;

  top: -10%;

  left: 66%;

  opacity: 1.444052367;

  transform: rotate(82.5906656882deg);

  -webkit-animation: drop-36 4.612916749s 0.6492899658s infinite;

  animation: drop-36 4.612916749s 0.6492899658s infinite;
}

@-webkit-keyframes drop-36 {
  100% {
    top: 110%;

    left: 74%;
  }
}
@keyframes drop-36 {
  100% {
    top: 110%;

    left: 74%;
  }
}

.confetti-37 {
  width: 1px;

  height: 0.4px;

  background-color: #d13447;

  top: -10%;

  left: 5%;

  opacity: 1.3379200114;

  transform: rotate(300.2345073801deg);

  -webkit-animation: drop-37 4.1673422055s 0.5234789221s infinite;

  animation: drop-37 4.1673422055s 0.5234789221s infinite;
}

@-webkit-keyframes drop-37 {
  100% {
    top: 110%;

    left: 7%;
  }
}

@keyframes drop-37 {
  100% {
    top: 110%;

    left: 7%;
  }
}

.confetti-38 {
  width: 1px;

  height: 0.4px;

  background-color: #263672;

  top: -10%;

  left: 29%;

  opacity: 0.9636065498;

  transform: rotate(78.2705713222deg);

  -webkit-animation: drop-38 4.9499978657s 0.4908829598s infinite;

  animation: drop-38 4.9499978657s 0.4908829598s infinite;
}

@-webkit-keyframes drop-38 {
  100% {
    top: 110%;

    left: 42%;
  }
}

@keyframes drop-38 {
  100% {
    top: 110%;

    left: 42%;
  }
}

.confetti-39 {
  width: 4px;

  height: 1.6px;

  background-color: #d13447;

  top: -10%;

  left: 21%;

  opacity: 1.4731050068;

  transform: rotate(267.3237741603deg);

  -webkit-animation: drop-39 4.8702756883s 0.3035975906s infinite;

  animation: drop-39 4.8702756883s 0.3035975906s infinite;
}

@-webkit-keyframes drop-39 {
  100% {
    top: 110%;

    left: 23%;
  }
}

@keyframes drop-39 {
  100% {
    top: 110%;

    left: 23%;
  }
}

.confetti-40 {
  width: 6px;

  height: 2.4px;

  background-color: #263672;

  top: -10%;

  left: 87%;

  opacity: 0.7501399849;

  transform: rotate(178.566264363deg);

  -webkit-animation: drop-40 4.0209287683s 0.5652906797s infinite;

  animation: drop-40 4.0209287683s 0.5652906797s infinite;
}

@-webkit-keyframes drop-40 {
  100% {
    top: 110%;

    left: 97%;
  }
}

@keyframes drop-40 {
  100% {
    top: 110%;

    left: 97%;
  }
}

.confetti-41 {
  width: 3px;

  height: 1.2px;

  background-color: #263672;

  top: -10%;

  left: 63%;

  opacity: 0.5144113247;

  transform: rotate(66.4991646896deg);

  -webkit-animation: drop-41 4.9047941916s 0.6659290057s infinite;

  animation: drop-41 4.9047941916s 0.6659290057s infinite;
}

@-webkit-keyframes drop-41 {
  100% {
    top: 110%;

    left: 74%;
  }
}

@keyframes drop-41 {
  100% {
    top: 110%;

    left: 74%;
  }
}

.confetti-42 {
  width: 1px;

  height: 0.4px;

  background-color: #263672;

  top: -10%;

  left: 56%;

  opacity: 1.3735600894;

  transform: rotate(199.6505354362deg);

  -webkit-animation: drop-42 4.0562634898s 0.575078972s infinite;

  animation: drop-42 4.0562634898s 0.575078972s infinite;
}

@-webkit-keyframes drop-42 {
  100% {
    top: 110%;

    left: 65%;
  }
}

@keyframes drop-42 {
  100% {
    top: 110%;

    left: 65%;
  }
}

.confetti-43 {
  width: 7px;

  height: 2.8px;

  background-color: #d13447;

  top: -10%;

  left: 1%;

  opacity: 1.2945217965;

  transform: rotate(153.7967759648deg);

  -webkit-animation: drop-43 4.7382782822s 0.4213782117s infinite;

  animation: drop-43 4.7382782822s 0.4213782117s infinite;
}

@-webkit-keyframes drop-43 {
  100% {
    top: 110%;

    left: 9%;
  }
}

@keyframes drop-43 {
  100% {
    top: 110%;

    left: 9%;
  }
}

.confetti-44 {
  width: 6px;

  height: 2.4px;

  background-color: #ffbf00;

  top: -10%;

  left: 5%;

  opacity: 1.2051834749;

  transform: rotate(350.0965059831deg);

  -webkit-animation: drop-44 4.3251732734s 0.5655308045s infinite;

  animation: drop-44 4.3251732734s 0.5655308045s infinite;
}

@-webkit-keyframes drop-44 {
  100% {
    top: 110%;

    left: 11%;
  }
}

@keyframes drop-44 {
  100% {
    top: 110%;

    left: 11%;
  }
}

.confetti-45 {
  width: 2px;

  height: 0.8px;

  background-color: #d13447;

  top: -10%;

  left: 51%;

  opacity: 1.457803423;

  transform: rotate(199.6919401068deg);

  -webkit-animation: drop-45 4.6932855594s 0.4729727835s infinite;

  animation: drop-45 4.6932855594s 0.4729727835s infinite;
}

@-webkit-keyframes drop-45 {
  100% {
    top: 110%;

    left: 61%;
  }
}

@keyframes drop-45 {
  100% {
    top: 110%;

    left: 61%;
  }
}

.confetti-46 {
  width: 2px;

  height: 0.8px;

  background-color: #263672;

  top: -10%;

  left: 69%;

  opacity: 0.7005630969;

  transform: rotate(156.332642765deg);

  -webkit-animation: drop-46 4.0979854192s 0.3927948562s infinite;

  animation: drop-46 4.0979854192s 0.3927948562s infinite;
}

@-webkit-keyframes drop-46 {
  100% {
    top: 110%;

    left: 82%;
  }
}

@keyframes drop-46 {
  100% {
    top: 110%;

    left: 82%;
  }
}

.confetti-47 {
  width: 1px;

  height: 0.4px;

  background-color: #d13447;

  top: -10%;

  left: 50%;

  opacity: 1.2357061922;

  transform: rotate(202.4937855622deg);

  -webkit-animation: drop-47 4.826334104s 0.9396178336s infinite;

  animation: drop-47 4.826334104s 0.9396178336s infinite;
}

@-webkit-keyframes drop-47 {
  100% {
    top: 110%;

    left: 54%;
  }
}

@keyframes drop-47 {
  100% {
    top: 110%;

    left: 54%;
  }
}

.confetti-48 {
  width: 6px;

  height: 2.4px;

  background-color: #d13447;

  top: -10%;

  left: 98%;

  opacity: 0.7944116579;

  transform: rotate(41.6714460709deg);

  -webkit-animation: drop-48 4.909857004s 0.3466841791s infinite;

  animation: drop-48 4.909857004s 0.3466841791s infinite;
}

@-webkit-keyframes drop-48 {
  100% {
    top: 110%;

    left: 103%;
  }
}

@keyframes drop-48 {
  100% {
    top: 110%;

    left: 103%;
  }
}

.confetti-49 {
  width: 8px;

  height: 3.2px;

  background-color: #d13447;

  top: -10%;

  left: 20%;

  opacity: 0.8338676751;

  transform: rotate(189.2878191715deg);

  -webkit-animation: drop-49 4.9941822502s 0.8149073909s infinite;

  animation: drop-49 4.9941822502s 0.8149073909s infinite;
}

@-webkit-keyframes drop-49 {
  100% {
    top: 110%;

    left: 24%;
  }
}

@keyframes drop-49 {
  100% {
    top: 110%;

    left: 24%;
  }
}

.confetti-50 {
  width: 1px;

  height: 0.4px;

  background-color: #ffbf00;

  top: -10%;

  left: 27%;

  opacity: 0.751384348;

  transform: rotate(68.3030241206deg);

  -webkit-animation: drop-50 4.7499969971s 0.1950666599s infinite;

  animation: drop-50 4.7499969971s 0.1950666599s infinite;
}

@-webkit-keyframes drop-50 {
  100% {
    top: 110%;

    left: 30%;
  }
}

@keyframes drop-50 {
  100% {
    top: 110%;

    left: 30%;
  }
}

.confetti-51 {
  width: 5px;

  height: 2px;

  background-color: #d13447;

  top: -10%;

  left: 13%;

  opacity: 1.009533945;

  transform: rotate(43.7201621681deg);

  -webkit-animation: drop-51 4.7929248026s 0.037254913s infinite;

  animation: drop-51 4.7929248026s 0.037254913s infinite;
}

@-webkit-keyframes drop-51 {
  100% {
    top: 110%;

    left: 22%;
  }
}

@keyframes drop-51 {
  100% {
    top: 110%;

    left: 22%;
  }
}

.confetti-52 {
  width: 2px;

  height: 0.8px;

  background-color: #ffbf00;

  top: -10%;

  left: 78%;

  opacity: 1.0073542953;

  transform: rotate(58.8345236964deg);

  -webkit-animation: drop-52 4.4853383098s 0.0294498589s infinite;

  animation: drop-52 4.4853383098s 0.0294498589s infinite;
}

@-webkit-keyframes drop-52 {
  100% {
    top: 110%;

    left: 92%;
  }
}

@keyframes drop-52 {
  100% {
    top: 110%;

    left: 92%;
  }
}

.confetti-53 {
  width: 7px;

  height: 2.8px;

  background-color: #263672;

  top: -10%;

  left: 93%;

  opacity: 1.2879096874;

  transform: rotate(259.4375299486deg);

  -webkit-animation: drop-53 4.7688666233s 0.6882071255s infinite;

  animation: drop-53 4.7688666233s 0.6882071255s infinite;
}

@-webkit-keyframes drop-53 {
  100% {
    top: 110%;

    left: 94%;
  }
}

@keyframes drop-53 {
  100% {
    top: 110%;

    left: 94%;
  }
}

.confetti-54 {
  width: 8px;

  height: 3.2px;

  background-color: #263672;

  top: -10%;

  left: 86%;

  opacity: 0.9396058174;

  transform: rotate(230.0495091352deg);

  -webkit-animation: drop-54 4.6229341386s 0.3148193864s infinite;

  animation: drop-54 4.6229341386s 0.3148193864s infinite;
}

@-webkit-keyframes drop-54 {
  100% {
    top: 110%;

    left: 100%;
  }
}

@keyframes drop-54 {
  100% {
    top: 110%;

    left: 100%;
  }
}

.confetti-55 {
  width: 4px;

  height: 1.6px;

  background-color: #ffbf00;

  top: -10%;

  left: 77%;

  opacity: 0.8739220034;

  transform: rotate(253.120596852deg);

  -webkit-animation: drop-55 4.2656633445s 0.4133138201s infinite;

  animation: drop-55 4.2656633445s 0.4133138201s infinite;
}

@-webkit-keyframes drop-55 {
  100% {
    top: 110%;

    left: 92%;
  }
}

@keyframes drop-55 {
  100% {
    top: 110%;

    left: 92%;
  }
}

.confetti-56 {
  width: 2px;

  height: 0.8px;

  background-color: #d13447;

  top: -10%;

  left: 66%;

  opacity: 1.332541138;

  transform: rotate(301.7102277503deg);

  -webkit-animation: drop-56 4.9947887538s 0.1337158727s infinite;

  animation: drop-56 4.9947887538s 0.1337158727s infinite;
}

@-webkit-keyframes drop-56 {
  100% {
    top: 110%;

    left: 80%;
  }
}

@keyframes drop-56 {
  100% {
    top: 110%;

    left: 80%;
  }
}

.confetti-57 {
  width: 7px;

  height: 2.8px;

  background-color: #263672;

  top: -10%;

  left: 36%;

  opacity: 0.6307289366;

  transform: rotate(88.5494994477deg);

  -webkit-animation: drop-57 4.414284284s 0.0743747093s infinite;

  animation: drop-57 4.414284284s 0.0743747093s infinite;
}

@-webkit-keyframes drop-57 {
  100% {
    top: 110%;

    left: 45%;
  }
}

@keyframes drop-57 {
  100% {
    top: 110%;

    left: 45%;
  }
}

.confetti-58 {
  width: 1px;

  height: 0.4px;

  background-color: #d13447;

  top: -10%;

  left: 45%;

  opacity: 0.5410916937;

  transform: rotate(182.1443019431deg);

  -webkit-animation: drop-58 4.7939955295s 0.7662937115s infinite;

  animation: drop-58 4.7939955295s 0.7662937115s infinite;
}

@-webkit-keyframes drop-58 {
  100% {
    top: 110%;

    left: 58%;
  }
}

@keyframes drop-58 {
  100% {
    top: 110%;

    left: 58%;
  }
}

.confetti-59 {
  width: 2px;

  height: 0.8px;

  background-color: #263672;

  top: -10%;

  left: 18%;

  opacity: 0.8878568952;

  transform: rotate(345.5389647225deg);

  -webkit-animation: drop-59 4.9694176236s 0.9431513534s infinite;

  animation: drop-59 4.9694176236s 0.9431513534s infinite;
}

@-webkit-keyframes drop-59 {
  100% {
    top: 110%;

    left: 30%;
  }
}

@keyframes drop-59 {
  100% {
    top: 110%;

    left: 30%;
  }
}

.confetti-60 {
  width: 5px;

  height: 2px;

  background-color: #d13447;

  top: -10%;

  left: 29%;

  opacity: 0.8193619613;

  transform: rotate(279.2296622238deg);

  -webkit-animation: drop-60 4.4264794562s 0.1034952038s infinite;

  animation: drop-60 4.4264794562s 0.1034952038s infinite;
}

@-webkit-keyframes drop-60 {
  100% {
    top: 110%;

    left: 39%;
  }
}

@keyframes drop-60 {
  100% {
    top: 110%;

    left: 39%;
  }
}

.confetti-61 {
  width: 4px;

  height: 1.6px;

  background-color: #ffbf00;

  top: -10%;

  left: 24%;

  opacity: 0.6957550113;

  transform: rotate(93.5722269406deg);

  -webkit-animation: drop-61 4.3589425289s 0.0668523351s infinite;

  animation: drop-61 4.3589425289s 0.0668523351s infinite;
}

@-webkit-keyframes drop-61 {
  100% {
    top: 110%;

    left: 37%;
  }
}

@keyframes drop-61 {
  100% {
    top: 110%;

    left: 37%;
  }
}

.confetti-62 {
  width: 6px;

  height: 2.4px;

  background-color: #263672;

  top: -10%;

  left: 71%;

  opacity: 0.5992396402;

  transform: rotate(213.0723808476deg);

  -webkit-animation: drop-62 4.5068554949s 0.3005495494s infinite;

  animation: drop-62 4.5068554949s 0.3005495494s infinite;
}

@-webkit-keyframes drop-62 {
  100% {
    top: 110%;

    left: 83%;
  }
}

@keyframes drop-62 {
  100% {
    top: 110%;

    left: 83%;
  }
}

.confetti-63 {
  width: 3px;

  height: 1.2px;

  background-color: #d13447;

  top: -10%;

  left: 39%;

  opacity: 1.2881666404;

  transform: rotate(159.6554205525deg);

  -webkit-animation: drop-63 4.8541329368s 0.1439415887s infinite;

  animation: drop-63 4.8541329368s 0.1439415887s infinite;
}

@-webkit-keyframes drop-63 {
  100% {
    top: 110%;

    left: 54%;
  }
}

@keyframes drop-63 {
  100% {
    top: 110%;

    left: 54%;
  }
}

.confetti-64 {
  width: 2px;

  height: 0.8px;

  background-color: #263672;

  top: -10%;

  left: 34%;

  opacity: 1.3763328397;

  transform: rotate(119.729191981deg);

  -webkit-animation: drop-64 4.3517689628s 0.6080558992s infinite;

  animation: drop-64 4.3517689628s 0.6080558992s infinite;
}

@-webkit-keyframes drop-64 {
  100% {
    top: 110%;

    left: 48%;
  }
}

@keyframes drop-64 {
  100% {
    top: 110%;

    left: 48%;
  }
}

.confetti-65 {
  width: 8px;

  height: 3.2px;

  background-color: #d13447;

  top: -10%;

  left: 15%;

  opacity: 0.5535257308;

  transform: rotate(128.4117341637deg);

  -webkit-animation: drop-65 4.3045693762s 0.3106141506s infinite;

  animation: drop-65 4.3045693762s 0.3106141506s infinite;
}

@-webkit-keyframes drop-65 {
  100% {
    top: 110%;

    left: 23%;
  }
}

@keyframes drop-65 {
  100% {
    top: 110%;

    left: 23%;
  }
}

.confetti-66 {
  width: 5px;

  height: 2px;

  background-color: #d13447;

  top: -10%;

  left: 7%;

  opacity: 1.1695850653;

  transform: rotate(222.6319846994deg);

  -webkit-animation: drop-66 4.9513380261s 0.9409821766s infinite;

  animation: drop-66 4.9513380261s 0.9409821766s infinite;
}

@-webkit-keyframes drop-66 {
  100% {
    top: 110%;

    left: 12%;
  }
}

@keyframes drop-66 {
  100% {
    top: 110%;

    left: 12%;
  }
}

.confetti-67 {
  width: 2px;

  height: 0.8px;

  background-color: #263672;

  top: -10%;

  left: 23%;

  opacity: 1.2062529474;

  transform: rotate(129.5316119996deg);

  -webkit-animation: drop-67 4.7082133729s 0.5370632858s infinite;

  animation: drop-67 4.7082133729s 0.5370632858s infinite;
}

@-webkit-keyframes drop-67 {
  100% {
    top: 110%;

    left: 32%;
  }
}

@keyframes drop-67 {
  100% {
    top: 110%;

    left: 32%;
  }
}

.confetti-68 {
  width: 7px;

  height: 2.8px;

  background-color: #d13447;

  top: -10%;

  left: 6%;

  opacity: 1.3720974433;

  transform: rotate(154.4549289871deg);

  -webkit-animation: drop-68 4.3767660646s 0.9739485928s infinite;

  animation: drop-68 4.3767660646s 0.9739485928s infinite;
}

@-webkit-keyframes drop-68 {
  100% {
    top: 110%;

    left: 12%;
  }
}

@keyframes drop-68 {
  100% {
    top: 110%;

    left: 12%;
  }
}

.confetti-69 {
  width: 5px;

  height: 2px;

  background-color: #d13447;

  top: -10%;

  left: 61%;

  opacity: 0.9509603204;

  transform: rotate(229.1418025082deg);

  -webkit-animation: drop-69 4.2013065675s 0.7751963544s infinite;

  animation: drop-69 4.2013065675s 0.7751963544s infinite;
}

@-webkit-keyframes drop-69 {
  100% {
    top: 110%;

    left: 69%;
  }
}


/* ============================ Home page techstack slider styles start =================================  */
.slider {
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
  color: #dcdcdc;
}
.slider i.fa.fa-circle {
  font-size: 11px;
}
.slide-track {
  width: calc(240px * 20);
  display: flex;
  animation: scroll 30s linear infinite;
  justify-content: space-between;
}

.slide-track2 {
  width: calc(240px * 20);
  display: flex;
  animation: scroll2 30s linear infinite;
  justify-content: space-between;
}

.slide-text {
  display: grid;
  place-items: center;
  transition: 0.5s;
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
}

.link {
  color: #ffffff;
  font-size: 15px;
  margin-top: 50px;
  text-decoration: underline;
  text-underline-offset: 8px;
  font-family: "Comme", "ExtraBold";
  text-align: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-150px * 10));
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(calc(-170px * 10));
  }
  100% {
    transform: translateX(0px);
  }
}

@media screen and (max-width: 1366px) {
  .slide-text {
    font-size: 25px;
  }
  .slide-track {
    width: calc(210px * 20) !important;
  }
}

@media screen and (max-width: 768px) {
  .slide-track {
    width: calc(180px * 20);
    animation: scroll 30s linear infinite;
  }

  .slide-track2 {
    width: calc(220px * 20);
    animation: scroll2 30s linear infinite;
  }

  .slide-text {
    width: 120px;
  }

  .slide-track2 .slide-text {
    width: 150px;
  }

  .slide-text {
    font-size: 18px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-150px * 10));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(calc(-170px * 10));
    }
    100% {
      transform: translateX(0px);
    }
  }
}

@media screen and (max-width: 450px) {
  .slide-track {
    width: calc(120px * 20) !important;
    animation: scroll 30s linear infinite;
  }

  .slide-track2 {
    width: calc(150px * 20);
    animation: scroll2 30s linear infinite;
  }

  .slide-text {
    font-size: 15px;
  }
}
/* ============================ Home page techstack slider styles end =================================  */
.techstackTabs .nav-link {
  background-color: transparent !important;
  color: #ffffff !important;
  border: 0 !important;
  font-size: 21px;
  font-family: "Comme", "ExtraBold";
}

.techstackTabs .nav-link.active {
  border-bottom: 2px solid #fff !important;
}
.techstackTabs .active {
  width: auto !important;
}
.techstackTabs .nav-tabs {
  border: 0 !important;
  justify-content: center;
}
.techstackTabs .tab-content {
  width: 100%;
  margin: 20px 0;
}

.techstackTabs .service-cardimg2 {
  height: 120px;
}

.techstackTabs .sub-head {
  color: #ffffff;
  font-size: 20px;
  text-align: center;
}
/* ============================ Home page techstack Heading animation styles1 start =================================  */
/* span.frame1, span.frame2 {
	-webkit-animation: none;
	-moz-animation: none;
	-ms-animation: none;
	animation: none;
}
span.frame1 span, span.frame2 span {
	-webkit-animation: blurFadeIn 3s ease-in  backwards;
	-moz-animation: blurFadeIn 1s ease-in  backwards;
	-ms-animation: blurFadeIn 3s ease-in  backwards;
	animation: blurFadeIn 3s ease-in  backwards;
}
span.frame1 span:nth-child(2) {
	-webkit-animation-delay: 1s;
	-moz-animation-delay: 1s;
	-ms-animation-delay: 1s;
	animation-delay: 1s;
}
span.frame1 span:nth-child(3) {
	-webkit-animation-delay: 2s;
	-moz-animation-delay: 2s;
	-ms-animation-delay: 2s;
	animation-delay: 2s;
}

span.frame2 span:nth-child(1) {
	-webkit-animation-delay: 3s;
	-moz-animation-delay: 3s;
	-ms-animation-delay: 3s;
	animation-delay: 3s;
}
span.frame2 span:nth-child(2) {
	-webkit-animation-delay: 4s;
	-moz-animation-delay: 4s;
	-ms-animation-delay: 4s;
	animation-delay: 4s;
}
@-webkit-keyframes blurFadeIn {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px #fff;
		-webkit-transform: scale(1.3);
	}
	50% {
		opacity: 0.5;
		text-shadow: 0px 0px 10px #fff;
		-webkit-transform: scale(1.1);
	}
	100% {
		opacity: 1;
		text-shadow: 0px 0px 1px #fff;
		-webkit-transform: scale(1);
	}
}

@-moz-keyframes blurFadeIn {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px #fff;
		-moz-transform: scale(1.3);
	}
	100% {
		opacity: 1;
		text-shadow: 0px 0px 1px #fff;
		-moz-transform: scale(1);
	}
}

@keyframes blurFadeIn {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px #fff;
		transform: scale(1.3);
	}
	50% {
		opacity: 0.5;
		text-shadow: 0px 0px 10px #fff;
		transform: scale(1.1);
	}
	100% {
		opacity: 1;
		text-shadow: 0px 0px 1px #fff;
		transform: scale(1);
	}
} */
/* ============================ Home page techstack Heading animation styles1 end =================================  */


/* ============================ Home page techstack Heading animation styles start =================================  */
/* .drop-main {
  display: flex;
  justify-content: center;
}
.c {
  opacity: 0;
  animation: drop 0.4s linear forwards;
  color: #017DB8;
}
.r {
  opacity: 0;
  animation: drop 0.4s linear forwards 0.2s;
  color: #017DB8;
}
.e {
  opacity: 0;
  animation: drop 0.4s linear forwards 0.4s;
  color: #017DB8;
}
.d {
  opacity: 0;
  animation: drop 0.4s linear forwards 0.6s;
  color: #017DB8;
}
.i {
  opacity: 0;
  animation: drop 0.4s linear forwards 0.8s;
  color: #017DB8;
}
.b {
  opacity: 0;
  animation: drop 0.4s linear forwards 1.0s;
  color: #017DB8;
}
.l {
  opacity: 0;
  animation: drop 0.4s linear forwards 1.2s;
  color: #017DB8;
}
.e1 {
  opacity: 0;
  animation: drop 0.4s linear forwards 1.4s;
  color: #017DB8;
}
.p0 {
  opacity: 0;
  animation: drop 0.4s linear forwards 1.6s;
  color: #017DB8;
}
.l1 {
  opacity: 0;
  animation: drop 0.4s linear forwards 1.8s;
  color: #017DB8;
}
.a {
  opacity: 0;
  animation: drop 0.4s linear forwards 2.0s;
  color: #017DB8;
}
.t {
  opacity: 0;
  animation: drop 0.4s linear forwards 2.2s;
  color: #017DB8;
}
.f {
  opacity: 0;
  animation: drop 0.4s linear forwards 2.4s;
  color: #017DB8;
}
.o {
  opacity: 0;
  animation: drop 0.4s linear forwards 2.6s;
  color: #017DB8;
}
.r1 {
  opacity: 0;
  animation: drop 0.4s linear forwards 2.8s;
  color: #017DB8;
}
.m {
  opacity: 0;
  animation: drop 0.4s linear forwards 3.0s;
  color: #017DB8;
}
.s {
  opacity: 0;
  animation: drop 0.4s linear forwards 3.2s;
  color: #017DB8;
}
.f1 {
  opacity: 0;
  animation: drop 0.4s linear forwards 3.4s;
  color: #017DB8;
}
.o1 {
  opacity: 0;
  animation: drop 0.4s linear forwards 3.6s;
  color: #017DB8;
}
.r2 {
  opacity: 0;
  animation: drop 0.4s linear forwards 3.8s;
  color: #017DB8;
}
.y {
  opacity: 0;
  animation: drop 0.4s linear forwards 4.0s;
  color: #ffffff;
}
.o2 {
  opacity: 0;
  animation: drop 0.4s linear forwards 4.2s;
  color: #ffffff;
}
.u {
  opacity: 0;
  animation: drop 0.4s linear forwards 4.4s;
  color: #ffffff;
}
.r3 {
  opacity: 0;
  animation: drop 0.4s linear forwards 4.6s;
  color: #ffffff;
}
.p1 {
  opacity: 0;
  animation: drop 0.4s linear forwards 4.8s;
  color: #ffffff;
}
.r4 {
  opacity: 0;
  animation: drop 0.4s linear forwards 5.0s;
  color: #ffffff;
}
.o3 {
  opacity: 0;
  animation: drop 0.4s linear forwards 5.2s;
  color: #ffffff;
}
.d1 {
  opacity: 0;
  animation: drop 0.4s linear forwards 5.4s;
  color: #ffffff;
}
.u1 {
  opacity: 0;
  animation: drop 0.4s linear forwards 5.6s;
  color: #ffffff;
}
.c1 {
  opacity: 0;
  animation: drop 0.4s linear forwards 5.8s;
  color: #ffffff;
}
.t1 {
  opacity: 0;
  animation: drop 0.4s linear forwards 6.0s;
  color: #ffffff;
}
.s1 {
  opacity: 0;
  animation: drop 0.4s linear forwards 6.2s;
  color: #ffffff;
}

@keyframes drop {
  0% {
      transform: translateY(-200px);
      opacity: 0;
  }
  50% {
      transform: translateY(0px);
      opacity: 1;
  }
  65% {
      transform: translateY(-17px);
      opacity: 1;
  }
  75% {
      transform: translateY(-22px);
      opacity: 1;
  }
  100% {
      transform: translateY(0px);
      opacity: 1;
  }
} */

/* ============================ Home page techstack Heading animation styles end =================================  */
/****** toast *****/
.fade-in-image {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

.toast {
  position: absolute;
  bottom: 39px;
  width: auto;
  background-color: #227c22 !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/****** toast *****/


.wp-caption {
  position: relative;
  padding: 0;
  margin: 0;
  }
  .wp-caption img {
  display: block;
  max-width: 100%;
  height: auto;
  }
  .wp-caption-text {
  position: absolute;
  width: 100%;
  color: #fff;
  left: 0;
  bottom: 0;
  padding: 0.75em 1em;
  z-index: 2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgba(33, 131, 187, 0.7);
  -webkit-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out;
  border-bottom-left-radius:10px;
  border-bottom-right-radius: 10px;
  font-size: 11px;
  background-image: linear-gradient(to bottom left, #00dcff3b, #262625b3);
  }

  .service2 .wp-caption-text {
    min-height: 93px;
  }

  .wp-caption-text p {
    margin: 0;
  }

    .kc-item {
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      padding: 20px;
    }

    .arrows {
      color: red;
    }

    .serv_arrows {
      color: rgb(33, 131, 187);
      font-size: 32px;
      width: 67px;
      z-index: 22;
      cursor: pointer;
      background-color: white;
      padding: 9px;
      border-radius: 50%;
      border: 1px solid rgb(33, 131, 187);
  }

  .kc_slider {
    width: 70%;
  }

  .success {
    border: 8px solid #f5f5f5;
    border-radius: 10px;
    width: 65%;
  }

  .processcard {
    width: 95% !important;
  display: flex !important;
  align-items: center;
  }

  .process-card {
    background-color: #228DC045;
    padding: 20px;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .process-cardimg {
    width: 65px;;
  }

  .processcard .card-paragraph {
    font: normal normal 500 12px/20px Poppins;
}

  .support {
    border: 2px solid #BEDBE9;
    border-radius: 11px;
    padding: 20px;
    width: 63%;
    justify-content: end;
    margin-top: 50px;
  }

  .support_img {
    position: absolute;
    width: 150px;
    left: 0px;
    bottom: 0px;
    z-index: 1;
  }
  .support_img2 {
    position: absolute;
    right: 0;
    height: 100px;
}
.support .aboutHead1 {
  font: normal normal 500 14px/10px Poppins;
}
.support_img1 {
  position: absolute;
  left: 0;
  top: 0;
  height: 100px;
}

  .support_img + div .aboutHead1 {
    font: normal normal 500 16px/15px Poppins;
}

.support .card-paragraph {
  font: normal normal 500 12px/20px Poppins;
}

.support_img + div .callus {
  font-size: 20px !important;
}

  .callus {
    color: #3167BA;
    font-size: 16px;
    font-weight: 700;
  }

  .callus1 {
    color: #3167BA;
    font-size: 13px;
    font-weight: 700;
    border: 1px solid #BEDBE9;
    padding: 10px;
    border-radius: 5px;
    margin-left: 25px;
    background: #fff;
    z-index: 0;
  }

  .callus1 img {
    width: 37px;
    background-color: #e7e7e7;
    padding: 7px;
    border-radius: 5px;
    margin-top: -23px;
    margin-right: 10px;
}

.support p {
  display: inline-block;
  margin: 0;
}

.success img {
  width: 90%;
}

.success .card-paragraph, .processcard p  {
  margin: 0;
}